import React, { useEffect, useState } from 'react';
import Rating from 'react-rating';
import moment from 'moment';
import { useRead } from '~/hooks';
import usePortal from 'react-cool-portal';
import {
  Typography,
  Radar,
  Flag,
  BarChart,
  Frame,
  Dialog,
} from '~/components';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import {
  IconClock,
  IconReload,
  IconChart,
  IconFile,
  IconHat,
} from '~/icons';

function Detail({ match }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const licenses = [
    {
      key: 'license1_1',
      title: '指導教育責任者',
      section: '１号警備',
      level: null,
    },
    {
      key: 'license1_2',
      title: '指導教育責任者',
      section: '２号警備',
      level: null,
    },
    {
      key: 'license1_3',
      title: '指導教育責任者',
      section: '３号警備',
      level: null,
    },
    {
      key: 'license1_4',
      title: '指導教育責任者',
      section: '４号警備',
      level: null,
    },
    {
      key: 'license2_1',
      title: '機械警備業務管理者',
      section: null,
      level: null,
    },
    {
      key: 'license3_1_1',
      title: '施設警備業務検定',
      section: null,
      level: '１級',
    },
    {
      key: 'license3_1_2',
      title: '施設警備業務検定',
      section: '１号警備',
      level: '２級',
    },
    {
      key: 'license3_1_3',
      title: '空港保安警備業務検定',
      section: '１号警備',
      level: '１級',
    },
    {
      key: 'license3_1_4',
      title: '空港保安警備業務検定',
      section: '１号警備',
      level: '２級',
    },
    {
      key: 'license3_2_1',
      title: '交通誘導警備業務検定',
      section: '２号警備',
      level: '１級',
    },
    {
      key: 'license3_2_2',
      title: '交通誘導警備業務検定',
      section: '２号警備',
      level: '２級',
    },
    {
      key: 'license3_2_3',
      title: '雑踏警備業務検定',
      section: '２号警備',
      level: '１級',
    },
    {
      key: 'license3_2_4',
      title: '雑踏警備業務検定',
      section: '２号警備',
      level: '２級',
    },
    {
      key: 'license3_3_1',
      title: '貴重品運搬警備業務検定',
      section: '３号警備',
      level: '１級',
    },
    {
      key: 'license3_3_2',
      title: '貴重品運搬警備業務検定',
      section: '３号警備',
      level: '２級',
    },
    {
      key: 'license3_3_3',
      title: '核燃料輸送警備業務検定',
      section: '３号警備',
      level: '１級',
    },
    {
      key: 'license3_3_4',
      title: '核燃料輸送警備業務検定',
      section: '３号警備',
      level: '２級',
    },
  ];
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false,
  });
  // ダイアログ用
  const [msg, setMessage] = useState("");

  /**
   * 学習状況を取得
   */
  const { data } = useRead(`study_detail_${match.params.id}`, `/manage/study/show`, {
    id: match.params.id,
  }, {
    refetchOnWindowFocus: false,
    retry: false,
    onError: error => {
      // 不正なリクエスト
      if (error.response.status === 400) {
        setMessage("不正なリクエストです。");
      }
      // 権限なし
      else if (error.response.status === 403) {
        setMessage("権限がありません。");
      }
      // 見つからない
      else if (error.response.status === 404) {
        setMessage("データが存在しません。");
      }
      // その他
      else {
        setMessage("原因不明のエラーが発生しました。");
      }
      show();
    },
  });

  useEffect(() => {
    setBreadcrumb(['学習状況', '詳細']);
  }, [setBreadcrumb]);

  const week_time = moment.duration(data?.result.week_time, 'seconds');
  const total_time = moment.duration(data?.result.total_time, 'seconds');

  return (
    <>
      <section className="l-section">
        <div className="u-profilebar">
          <div className="profilebar-name">
            <Typography variant="page">{data?.result.name}</Typography>
            <Typography variant="ruby">{data?.result.rubi}</Typography>
          </div>
          <div className="profilebar-col">
            <p className="profilebar-label"><IconClock />今週の学習時間</p>
            <div className="profilebar-score u-fraction">
              <span className="fraction-upper">{week_time.hours()}</span>
              <span className="fraction-suffix">時間</span>
              <span className="fraction-upper">{week_time.minutes()}</span>
              <span className="fraction-suffix">分</span>
            </div>
          </div>
          <div className="profilebar-col">
            <p className="profilebar-label"><IconReload />総学習時間</p>
            <div className="profilebar-score u-fraction">
              <span className="fraction-upper">{total_time.hours()}</span>
              <span className="fraction-suffix">時間</span>
              <span className="fraction-upper">{total_time.minutes()}</span>
              <span className="fraction-suffix">分</span>
            </div>
          </div>
          {/* <div className="profilebar-col">
            <p className="profilebar-label"><IconFire />試験日まで</p>
            <div className="profilebar-score u-fraction">
              <span className="fraction-upper">45</span>
              <span className="fraction-suffix">日</span>
            </div>
          </div> */}
        </div>
        <Typography variant="label">所持資格</Typography>
        <Frame>
          {licenses.some(license => !!data?.result[license.key]) ? (
            <table className="u-table-row">
              <thead>
                <tr>
                  <Typography variant="left" element="th">資格</Typography>
                  <Typography variant="left" element="th">警備区分</Typography>
                  <Typography variant="left" element="th">級</Typography>
                </tr>
              </thead>
              <tbody>
                {licenses.filter(license => !!data?.result[license.key]).map(license => (
                  <tr key={`license_${license.key}`}>
                    <td>{license.title}</td>
                    <td>{license.section ? license.section : '-'}</td>
                    <td>{license.level ? license.level : '-'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
              <Typography>データなし</Typography>
            )}
        </Frame>
      </section>
      <section className="l-section">
        <Typography variant="label"><IconFile />試験結果一覧</Typography>
        <Frame>
          {!!data?.result.exam_results.length ? (
            <table className="u-table-row">
              <thead>
                <tr>
                  <Typography variant="left" element="th">試験日</Typography>
                  <Typography variant="left" element="th">試験種別</Typography>
                  <Typography variant="left" element="th">公安</Typography>
                  <Typography variant="left" element="th">合否</Typography>
                </tr>
              </thead>
              <tbody>
                {data?.result.exam_results.map(exam => (
                  <tr key={`exam_results_${exam.id}`}>
                    <td>{moment(exam.exam_date).format('YYYY/MM/D')}</td>
                    <td>{exam.master_exam_type.name}</td>
                    <td>{exam.master_area_public_safety.name}</td>
                    <td>
                      <Flag isPassed={exam.is_passed}>{exam.is_passed ? '合格' : '不合格'}</Flag>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
              <Typography>データなし</Typography>
            )}
        </Frame>
      </section>
      <section className="l-section">
        <Typography variant="label"><IconChart />テスト成績</Typography>
        <div className="u-leaflet">
          <div className="leaflet-radar">
            <Radar data={data?.result} />
          </div>
          <div className="leaflet-total">
            <p className="leaflet-label">全体の理解度</p>
            <Rating
              className="u-rating"
              readonly
              initialRating={data?.result.rate.total_avg}
              emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2235.259%22%20height%3D%2233.562%22%20viewBox%3D%220%200%2035.259%2033.562%22%3E%20%3Cpath%20id%3D%22_ionicons_svg_md-star%22%20d%3D%22M71.63%2C90.98l10.895%2C6.583L79.642%2C85.146%2C89.259%2C76.8l-12.678-1.09L71.63%2C64%2C66.677%2C75.706%2C54%2C76.8l9.617%2C8.349L60.734%2C97.562Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
              fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2235.259%22%20height%3D%2233.562%22%20viewBox%3D%220%200%2035.259%2033.562%22%3E%20%3Cpath%20id%3D%22_ionicons_svg_md-star%22%20d%3D%22M71.63%2C90.98l10.895%2C6.583L79.642%2C85.146%2C89.259%2C76.8l-12.678-1.09L71.63%2C64%2C66.677%2C75.706%2C54%2C76.8l9.617%2C8.349L60.734%2C97.562Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
            />
          </div>
          <div className="leaflet-category">
            <p className="leaflet-label">カテゴリ別理解度</p>
            <div className="u-category">
              <p className="category-label">基本的事項</p>
              <Rating
                className="u-rating-small"
                readonly
                initialRating={data?.result.rate.rate1}
                emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
              />
            </div>
            <div className="u-category">
              <p className="category-label">関係法令</p>
              <Rating
                className="u-rating-small"
                readonly
                initialRating={data?.result.rate.rate2}
                emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
              />
            </div>
            <div className="u-category">
              <p className="category-label">車両等誘導</p>
              <Rating
                className="u-rating-small"
                readonly
                initialRating={data?.result.rate.rate3}
                emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
              />
            </div>
            <div className="u-category">
              <p className="category-label">応急処置</p>
              <Rating
                className="u-rating-small"
                readonly
                initialRating={data?.result.rate.rate4}
                emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
              />
            </div>
            <div className="u-category">
              <p className="category-label">その他</p>
              <Rating
                className="u-rating-small"
                readonly
                initialRating={data?.result.rate.rate5}
                emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="l-section">
        <Typography variant="label"><IconHat />受講履歴</Typography>
        <Frame>
          {!!data?.result.user_answer_practice_exam_results.length ? (
            <div className="u-parking">
              {data?.result.user_answer_practice_exam_results.map(result => (
                <div key={`user_answer_${result.id}`} className="parking-item">
                  <div className="parking-meta">
                    <time className="parking-date">{moment(result.created_at).format('YYYY年MM月DD日 HH:mm')}</time>
                    <div className="parking-rating">
                      <p>理解度</p>
                      <Rating
                        className="u-rating-small"
                        readonly
                        initialRating={result.total_comprehension_rate}
                        emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                        fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
                      />
                    </div>
                  </div>
                  <div className="parking-body">
                    <p className="parking-text">正解 [{result.total_correct_count}]<br />不正解 [{result.total_incorrect_count}]</p>
                    <div className="parking-score u-fraction">
                      <span className="fraction-upper">{result.total_score}</span>
                      <span className="fraction-border-tight">/</span>
                      <span className="fraction-lower">100</span>
                      <span className="fraction-suffix">点</span>
                    </div>
                    <div className="parking-time u-fraction">
                      <p className="fraction-text">時間</p>
                      <span className="fraction-upper">{moment.duration(result.total_time, 'seconds').minutes()}</span>
                      <span className="fraction-border-tight">/</span>
                      <span className="fraction-lower">60</span>
                      <span className="fraction-suffix">分</span>
                    </div>
                    <Flag isPassed={result.is_passed}>{result.is_passed ? '合格' : '不合格'}</Flag>
                  </div>
                </div>
              ))}
            </div>
          ) : (
              <Typography>データなし</Typography>
            )}
        </Frame>
      </section>
      {/* <section className="l-section">
        <Typography variant="label"><IconChart />VRスコア</Typography>
        <Frame>
          <table className="u-lattice">
            <thead>
              <tr>
                <Typography variant="left" element="th">&nbsp;</Typography>
                <Typography variant="left" element="th">最新</Typography>
                <Typography variant="left" element="th">総合</Typography>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>2人片側交互通行</th>
                <td>
                  {data?.result.vr.latest.question1.created_at !== '0000-00-00 00:00:00' && (
                    <time className="lattice-date">
                      {moment(data?.result.vr.latest.question1.created_at).format('YYYY年MM月DD日 HH:mm')}
                    </time>
                  )}
                  <div className="lattice-cell">
                    <Rating
                      className="u-rating-small"
                      readonly
                      initialRating={data?.result.vr.latest.question1.correct_count}
                      emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                      fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%2F%3E%3C%2Fsvg%3E" />}
                    />
                    <div className="lattice-score u-fraction">
                      <span className="fraction-upper">{data?.result.vr.latest.question1.correct_count}</span>
                      <span className="fraction-border-tight">/</span>
                      <span className="fraction-lower">5</span>
                      <span className="fraction-suffix">点</span>
                    </div>
                    <Flag className="lattice-flag" isHide={data?.result.vr.latest.question1.created_at === '0000-00-00 00:00:00'} isPassed={data?.result.vr.latest.question1.correct_count}>
                      {data?.result.vr.latest.question1.correct_count ? '合格' : '不合格'}
                    </Flag>
                  </div>
                </td>
                <td>
                  {!!data?.result.vr.total.question1.count && (
                    <time className="lattice-date">全{data?.result.vr.total.question1.count}回受講</time>
                  )}
                  <div className="lattice-cell">
                    <Rating
                      className="u-rating-small"
                      readonly
                      initialRating={Number(data?.result.vr.total.question1.avg_rate)}
                      emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                      fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%2F%3E%3C%2Fsvg%3E" />}
                    />
                    <div className="lattice-score u-fraction">
                      <span className="fraction-upper">{Math.round(Number(data?.result.vr.total.question1.avg_rate) * 10) / 10}</span>
                      <span className="fraction-border-tight">/</span>
                      <span className="fraction-lower">5</span>
                      <span className="fraction-suffix">点</span>
                    </div>
                    <Flag className="lattice-flag" isHide={!data?.result.vr.total.question1.count} isPassed={Math.round(Number(data?.result.vr.total.question1.avg_rate) * 10) / 10}>
                      {Math.round(Number(data?.result.vr.total.question1.avg_rate) * 10) / 10 ? '合格' : '不合格'}
                    </Flag>
                  </div>
                </td>
              </tr>
              <tr>
                <th>大旗による車両の誘導要領</th>
                <td>
                  {data?.result.vr.latest.question2.created_at !== '0000-00-00 00:00:00' && (
                    <time className="lattice-date">
                      {moment(data?.result.vr.latest.question2.created_at).format('YYYY年MM月DD日 HH:mm')}
                    </time>
                  )}
                  <div className="lattice-cell">
                    <Rating
                      className="u-rating-small"
                      readonly
                      initialRating={data?.result.vr.latest.question2.correct_count}
                      emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                      fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%2F%3E%3C%2Fsvg%3E" />}
                    />
                    <div className="lattice-score u-fraction">
                      <span className="fraction-upper">{data?.result.vr.latest.question2.correct_count}</span>
                      <span className="fraction-border-tight">/</span>
                      <span className="fraction-lower">5</span>
                      <span className="fraction-suffix">点</span>
                    </div>
                    <Flag className="lattice-flag" isHide={data?.result.vr.latest.question2.created_at === '0000-00-00 00:00:00'} isPassed={data?.result.vr.latest.question2.correct_count}>
                      {data?.result.vr.latest.question2.correct_count ? '合格' : '不合格'}
                    </Flag>
                  </div>
                </td>
                <td>
                  {!!data?.result.vr.total.question2.count && (
                    <time className="lattice-date">全{data?.result.vr.total.question2.count}回受講</time>
                  )}
                  <div className="lattice-cell">
                    <Rating
                      className="u-rating-small"
                      readonly
                      initialRating={Number(data?.result.vr.total.question2.avg_rate)}
                      emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                      fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%2F%3E%3C%2Fsvg%3E" />}
                    />
                    <div className="lattice-score u-fraction">
                      <span className="fraction-upper">{Math.round(Number(data?.result.vr.total.question2.avg_rate) * 10) / 10}</span>
                      <span className="fraction-border-tight">/</span>
                      <span className="fraction-lower">5</span>
                      <span className="fraction-suffix">点</span>
                    </div>
                    <Flag className="lattice-flag" isHide={!data?.result.vr.total.question2.count} isPassed={Math.round(Number(data?.result.vr.total.question2.avg_rate) * 10) / 10}>
                      {Math.round(Number(data?.result.vr.total.question2.avg_rate) * 10) / 10 ? '合格' : '不合格'}
                    </Flag>
                  </div>
                </td>
              </tr>
              <tr>
                <th>素手による車両の後進誘導要領</th>
                <td>
                  {data?.result.vr.latest.question3.created_at !== '0000-00-00 00:00:00' && (
                    <time className="lattice-date">
                      {moment(data?.result.vr.latest.question3.created_at).format('YYYY年MM月DD日 HH:mm')}
                    </time>
                  )}
                  <div className="lattice-cell">
                    <Rating
                      className="u-rating-small"
                      readonly
                      initialRating={data?.result.vr.latest.question3.correct_count}
                      emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                      fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%2F%3E%3C%2Fsvg%3E" />}
                    />
                    <div className="lattice-score u-fraction">
                      <span className="fraction-upper">{data?.result.vr.latest.question3.correct_count}</span>
                      <span className="fraction-border-tight">/</span>
                      <span className="fraction-lower">5</span>
                      <span className="fraction-suffix">点</span>
                    </div>
                    <Flag className="lattice-flag" isHide={data?.result.vr.latest.question3.created_at === '0000-00-00 00:00:00'} isPassed={data?.result.vr.latest.question3.correct_count}>
                      {data?.result.vr.latest.question3.correct_count ? '合格' : '不合格'}
                    </Flag>
                  </div>
                </td>
                <td>
                  {!!data?.result.vr.total.question3.count && (
                    <time className="lattice-date">全{data?.result.vr.total.question3.count}回受講</time>
                  )}
                  <div className="lattice-cell">
                    <Rating
                      className="u-rating-small"
                      readonly
                      initialRating={Number(data?.result.vr.total.question3.avg_rate)}
                      emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                      fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%2F%3E%3C%2Fsvg%3E" />}
                    />
                    <div className="lattice-score u-fraction">
                      <span className="fraction-upper">{Math.round(Number(data?.result.vr.total.question3.avg_rate) * 10) / 10}</span>
                      <span className="fraction-border-tight">/</span>
                      <span className="fraction-lower">5</span>
                      <span className="fraction-suffix">点</span>
                    </div>
                    <Flag className="lattice-flag" isHide={!data?.result.vr.total.question3.count} isPassed={Math.round(Number(data?.result.vr.total.question3.avg_rate) * 10) / 10}>
                      {Math.round(Number(data?.result.vr.total.question3.avg_rate) * 10) / 10 ? '合格' : '不合格'}
                    </Flag>
                  </div>
                </td>
              </tr>
              <tr>
                <th>二次災害防止要領</th>
                <td>
                  {data?.result.vr.latest.question4.created_at !== '0000-00-00 00:00:00' && (
                    <time className="lattice-date">
                      {moment(data?.result.vr.latest.question4.created_at).format('YYYY年MM月DD日 HH:mm')}
                    </time>
                  )}
                  <div className="lattice-cell">
                    <Rating
                      className="u-rating-small"
                      readonly
                      initialRating={data?.result.vr.latest.question4.correct_count}
                      emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                      fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%2F%3E%3C%2Fsvg%3E" />}
                    />
                    <div className="lattice-score u-fraction">
                      <span className="fraction-upper">{data?.result.vr.latest.question4.correct_count}</span>
                      <span className="fraction-border-tight">/</span>
                      <span className="fraction-lower">5</span>
                      <span className="fraction-suffix">点</span>
                    </div>
                    <Flag className="lattice-flag" isHide={data?.result.vr.latest.question4.created_at === '0000-00-00 00:00:00'} isPassed={data?.result.vr.latest.question4.correct_count}>
                      {data?.result.vr.latest.question4.correct_count ? '合格' : '不合格'}
                    </Flag>
                  </div>
                </td>
                <td>
                  {!!data?.result.vr.total.question4.count && (
                    <time className="lattice-date">全{data?.result.vr.total.question4.count}回受講</time>
                  )}
                  <div className="lattice-cell">
                    <Rating
                      className="u-rating-small"
                      readonly
                      initialRating={Number(data?.result.vr.total.question4.avg_rate)}
                      emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                      fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%236bd19a%22%2F%3E%3C%2Fsvg%3E" />}
                    />
                    <div className="lattice-score u-fraction">
                      <span className="fraction-upper">{Math.round(Number(data?.result.vr.total.question4.avg_rate) * 10) / 10}</span>
                      <span className="fraction-border-tight">/</span>
                      <span className="fraction-lower">5</span>
                      <span className="fraction-suffix">点</span>
                    </div>
                    <Flag className="lattice-flag" isHide={!data?.result.vr.total.question4.count} isPassed={Math.round(Number(data?.result.vr.total.question4.avg_rate) * 10) / 10}>
                      {Math.round(Number(data?.result.vr.total.question4.avg_rate) * 10) / 10 ? '合格' : '不合格'}
                    </Flag>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </Frame>
      </section> */}
      <section className="l-section">
        <Typography variant="label"><IconChart />学習時間</Typography>
        <BarChart data={data?.result} />
      </section>
      <Portal>
        <Dialog
          title="確認"
          message={msg}
          handleModalClose={hide}
          isShow={isShow}
        />
      </Portal>
    </>
  );
}

export default Detail;
