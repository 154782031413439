import React from 'react';
import clsx from 'clsx';

const Flag = React.forwardRef(function Flag(props, ref) {
  const {
    className,
    isPassed,
    isHide,
    ...other
  } = props;

  return (
    <div
      ref={ref}
      className={clsx('u-flag', className, isPassed && 'is-passed', isHide && 'is-hide')}
      {...other}
    />
  );
});

export default Flag;
