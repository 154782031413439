import React, { useState, useRef } from 'react';
import { useForm, ErrorMessage, Controller } from 'react-hook-form';
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils,
} from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { every } from 'lodash';
import 'draft-js/dist/Draft.css';
import {
  IconUser,
  IconSpeedometer,
  IconClock,
  IconTweet,
  IconGear,
  IconFile,
  IconBook,
  IconBookOpen,
  IconStar,
  IconChart,
  IconGraph,
  IconSignout,
  IconCheck,
  IconWarning,
  IconAngleDown,
} from '~/icons';
import {
  Typography,
  Button,
  Input,
  Field,
} from '~/components';

const defaultValues = {
  draft: EditorState.createEmpty(),
};

function Protected() {
  const { handleSubmit, register, formState, errors, reset, clearErrors, watch, setValue, setError, control } = useForm({ defaultValues });
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const watchAllFields = watch();

  const handleEditorSubmit = () => {
    const raw = convertToRaw(editorState.getCurrentContent());
    const isEmpty = every(raw.blocks, item => item.text.trim() === '');
    if (isEmpty) {
      setError('draft', {
        type: 'required',
        message: 'Required',
      });
    }
  };

  const handleBold = () => {
    const nextState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
    setEditorState(nextState);
  };

  const handleEdit = edit => {
    setEditorState(edit);
    if (editorState.getCurrentContent().hasText()) {
      delete errors.draft;
    }
  };

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">編集</Typography>
      </div>

      {/* <form onSubmit={handleSubmit(handleEditorSubmit)}>
        <div className="RichEditor-root">
          <Controller
            name="draft"
            control={control}
            render={({ value, onChange }) => (
              <Editor editorState={value} onChange={props => {
                clearErrors('draft');
                onChange(props);
              }} />
            )}
          />
        </div>
        {errors.draft && <Typography variant="error">{errors.draft.message}</Typography>}
        <Button type="submit">click</Button>
      </form> */}

      <form onSubmit={handleSubmit(handleEditorSubmit)}>
        <button type="button" onClick={handleBold}>BOLD</button>
        <Editor
          editorState={editorState}
          onChange={handleEdit}
        />
        {errors.draft && <Typography variant="error">{errors.draft.message}</Typography>}
        <Button type="submit">click</Button>
      </form>

      <Field label="会社名">
        <Input
          name="name"
          ref={register}
          className="field-col"
        />
      </Field>
      <Field label="メールアドレス">
        <Input
          name="name"
          ref={register}
          className="field-col"
        />
      </Field>
      <Field label="パスワード">
        <Input
          name="name"
          ref={register}
          className="field-col"
        />
      </Field>
      <div className="u-buttons">
        <Button variant="secondary">
          キャンセル
        </Button>
        <Button variant="primary">
          送信
        </Button>
      </div>
      <Button variant="icon">
        <IconGear />
      </Button>
      <Button variant="outline">
        <IconSignout />
        ログアウト
      </Button>

      <Input
        name="name"
        ref={register}
      />
      <Input
        name="search"
        variant="search"
        placeholder="キーワードで検索"
        ref={register}
      />
      <select name="aaaaa" ref={register} className="select-primary">
        <option value="aaa">あああ</option>
        <option value="bbb">いいい</option>
        <option value="ccc">ううう</option>
        <option value="ddd">えええ</option>
        <option value="eee">おおお</option>
      </select>
      <div style={{ display: 'flex', fontSize: '23px' }}>
        <IconAngleDown />
        <IconSpeedometer />
        <IconSignout />
        <IconUser />
        <IconClock />
        <IconGear />
        <IconTweet />
        <IconFile />
        <IconBook />
        <IconBookOpen />
        <IconStar />
        <IconChart />
        <IconGraph />
        <IconCheck />
        <IconWarning />
      </div>
    </section>
  );
}

export default Protected;
