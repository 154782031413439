import React, { useState, useEffect } from 'react';
import sanitizeHtml from 'sanitize-html';
import usePortal from 'react-cool-portal';
import {
  Typography,
  Button,
  Frame,
  Dialog,
} from '~/components';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import {
  useRead,
  useDelete,
} from '~/hooks';

function Detail({ history, match }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const [questionDelete, { isLoading: isDeleting }] = useDelete();
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false,
  });
  const sanitizeConfig = {
    allowedTags: ['span'],
    allowedAttributes: {
      span: ['title', 'comment', 'url', 'preference'],
    },
  };
  // ダイアログ用
  const [title, setTitle] = useState('');
  const [msg, setMessage] = useState('');
  const [isForm, setForm] = useState(false);

  /**
   * 企業ユーザー詳細を取得
   */
  const { status, data } = useRead(`question_detail_${match.params.id}`, `/manage/questions/show`, {
    id: match.params.id,
  }, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: () => {
      setBreadcrumb(['設問管理', '詳細']);
    },
    onError: error => {
      setTitle('確認');
      setForm(false);
      // 不正なリクエスト
      if (error.response.status === 400) {
        setMessage('不正なリクエストです。');
      }
      // 権限なし
      else if (error.response.status === 403) {
        setMessage('権限がありません。');
      }
      // 見つからない
      else if (error.response.status === 404) {
        setMessage('設問が存在しません。');
      }
      // その他
      else {
        setMessage('原因不明のエラーです。');
      }
      show();
    },
  });

  /**
   * 編集画面に遷移
   */
  function goEdit(result) {
    history.push({
      pathname: `/admin/question/edit/${result.id}`,
      state: result,
    });
  }

  /**
   * モーダルクローズ
   */
  function handleModalClose() {
    hide();
  }

  /**
   * 削除確認モーダルオープン
   */
  function handleConfirmModalOpen() {
    setTitle('コンテンツの削除');
    setMessage('削除しますか？');
    setForm(true);
    show();
  }

  /**
   * アドミンアカウントを削除
   */
  function handleDeleteClick() {
    // リクエスト中は抜ける
    if (isDeleting) return;
    // 削除リクエスト
    questionDelete({
      url: '/manage/questions/delete',
      params: {
        id: Number(data?.result.id),
      },
    }, {
      onSuccess: () => {
        history.push('/admin/question');
      },
      onError: error => {
        setTitle('確認');
        setForm(false);
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage('不正なリクエストです。');
        }
        // 権限なし
        else if (error.response.status === 403) {
          setMessage('権限がありません。');
        }
        // 見つからない
        else if (error.response.status === 404) {
          setMessage('設問が存在しません。');
        }
        // その他
        else {
          setMessage('原因不明のエラーです。');
        }
        show();
      },
    });
  }

  /**
   * 音声ファイルパスからファイル名を抽出
   */
  function nameFromPath(path) {
    let _path = path.split('/');
    return _path[_path.length - 1];
  }

  useEffect(() => {
    sessionStorage.removeItem('merisessqts_admin_questionFormData_edit');
  }, []);

  return (
    <>
      <section className="l-section">
        <div className="u-titlebar">
          <div className="titlebar-left">
            <Typography variant="page">詳細</Typography>
            {status === 'success' && <Button variant="minimum" className="titlebar-button" onClick={() => goEdit(data?.result)}>編集</Button>}
          </div>
          <div className="titlebar-right">
            {status === 'success' && <Button variant="warning" className="titlebar-button" onClick={handleConfirmModalOpen}>削除</Button>}
          </div>
        </div>
        <Typography variant="caption">カテゴリー</Typography>
        <Frame>
          <table className="u-table-column">
            <tbody>
              <tr>
                <th style={{ width: '180px' }}>カテゴリー</th>
                <td>{!!data?.result.question_category && data?.result.question_category.name}</td>
              </tr>
            </tbody>
          </table>
        </Frame>
        <hr className="u-separate-offset" noshade="true" />
        <Typography variant="caption">問題</Typography>
        <Frame>
          <table className="u-table-column">
            <tbody>
              <tr>
                <th style={{ width: '180px' }}>問題</th>
                <td dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(status === 'success' ? data?.result.question_html : '', sanitizeConfig)
                }} />
              </tr>
              <tr>
                <th style={{ width: '180px' }}>音声ファイル</th>
                <td>{!!data?.result.question_sound && nameFromPath(data?.result.question_sound)}</td>
              </tr>
            </tbody>
          </table>
        </Frame>
        <Frame>
          <table className="u-table-column">
            <tbody>
              <tr>
                <th style={{ width: '180px' }}>正解</th>
                <td dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(status === 'success' ? data?.result[`answer_${data?.result.answer_correct_number}_html`] : '', sanitizeConfig)
                }} />
              </tr>
              <tr>
                <th style={{ width: '180px' }}>音声ファイル</th>
                <td>{!!data?.result[`answer_${data?.result.answer_correct_number}_sound`] && nameFromPath(data?.result[`answer_${data?.result.answer_correct_number}_sound`])}</td>
              </tr>
            </tbody>
          </table>
        </Frame>
        {[...Array(5)].map((_, i) => {
          if (data?.result.answer_correct_number === i + 1) return false;
          return (
            <Frame key={`answers_list_${i}`}>
              <table className="u-table-column">
                <tbody>
                  <tr>
                    <th style={{ width: '180px' }}>選択肢{i + 1}</th>
                    <td dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(status === 'success' ? data?.result[`answer_${i + 1}_html`] : '', sanitizeConfig)
                    }} />
                  </tr>
                  <tr>
                    <th style={{ width: '180px' }}>音声ファイル</th>
                    <td>{!!data?.result[`answer_${i + 1}_sound`] && nameFromPath(data?.result[`answer_${i + 1}_sound`])}</td>
                  </tr>
                </tbody>
              </table>
            </Frame>
          );
        })}
        <hr className="u-separate-offset" noshade="true" />
        <Typography variant="caption">解説</Typography>
        <Frame>
          <table className="u-table-column">
            <tbody>
              <tr>
                <th style={{ width: '180px' }}>解説</th>
                <td dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(status === 'success' ? data?.result.answer_explanation_html : '', sanitizeConfig)
                }} />
              </tr>
              <tr>
                <th style={{ width: '180px' }}>音声ファイル</th>
                <td>{!!data?.result.answer_explanation_sound && nameFromPath(data?.result.answer_explanation_sound)}</td>
              </tr>
            </tbody>
          </table>
        </Frame>
      </section>
      <Portal>
        <Dialog
          title={title}
          message={msg}
          handleModalClose={handleModalClose}
          handleExecuteClick={handleDeleteClick}
          isShow={isShow}
          form={isForm}
          isExecuting={isDeleting}
        />
      </Portal>
    </>
  );
}

export default Detail;
