import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import ContentLoader from 'react-content-loader';
import usePortal from 'react-cool-portal';
import { useForm } from 'react-hook-form';
import {
  Typography,
  Frame,
  Button,
  Form,
  Textarea,
  Field,
  Dialog,
} from '~/components';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import {
  useRead,
  useUpdate,
  useDelete,
} from '~/hooks';
import {
  IconLoader,
  IconWarning,
} from '~/icons';

function Detail({ history, match, location }) {
  const [userId, setUserId] = useState();
  const [modalMode, setModalMode] = useState('');
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false,
  });
  const { handleSubmit, register, setValue } = useForm();
  const [userCommentEdit, { isLoading: isUpdating }] = useUpdate();
  const [attentionDataDelete, { isLoading: isDeleting }] = useDelete();
  // ダイアログ用
  const [title, setTitle] = useState("");
  const [msg, setMessage] = useState("");
  const [isForm, setForm] = useState(false);

  /**
   * 要注意人物詳細取得
   */
  const { status, data, refetch } = useRead(`limping_detail_${match.params.name}`, `/manage/users/app/limping/show`, {
    name: location.state ? location.state.name : '',
    rubi: location.state ? location.state.rubi : '',
    birth_date: location.state ? location.state.birth_date : '',
  }, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: response => {
      setBreadcrumb(['要注意人物一覧', response.result.name]);
    },
    onError: error => {
      setTitle("確認");
      setForm(false);
      // 不正なリクエスト
      if (error.response.status === 400) {
        setMessage("不正なリクエストです。");
      }
      // 権限なし
      else if (error.response.status === 403) {
        setMessage("権限がありません。");
      }
      // 見つからない
      else if (error.response.status === 404) {
        setMessage("データが存在しません。");
      }
      // その他
      else {
        setMessage("原因不明のエラーです。");
      }
      show();
    }
  });

  /**
   * モーダルクローズ
   */
  function handleModalClose() {
    hide();
  }

  /**
   * 編集モーダルオープン
   */
  function handleEditModalOpen(id, comment) {
    setTitle("フリーコメントを編集");
    setMessage("");
    setForm(false);
    setModalMode('edit');
    setUserId(id);
    setValue('retire_comment', comment);
    show();
  }

  /**
   * 削除確認モーダルオープン
   */
  function handleConfirmModalOpen() {
    setTitle("コンテンツの削除");
    setMessage("削除しますか？");
    setForm(true);
    setModalMode('delete');
    show();
  }

  /**
   * 企業評価のフリーコメントを編集
   */
  function onUserCommentEdit(params) {
    // リクエスト中は抜ける
    if (isUpdating) return;
    // 編集リクエスト
    userCommentEdit({
      url: '/manage/users/app/limping/update',
      params: {
        id: Number(userId),
        retire_comment: params.retire_comment,
      },
    }, {
      onSuccess: () => {
        refetch();
        hide();
      },
      onError: error => {
        setTitle("確認");
        setForm(false);
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage("不正なリクエストです。");
        }
        // 権限なし
        else if (error.response.status === 403) {
          setMessage("権限がありません。");
        }
        // 見つからない
        else if (error.response.status === 404) {
          setMessage("データが存在しません。");
        }
        // その他
        else {
          setMessage("原因不明のエラーです。");
        }
        show();
      },
    });
  }

  /**
   * 要注意人物情報を削除
   */
  function handleDeleteClick() {
    // リクエスト中は抜ける
    if (isDeleting) return;
    // 削除リクエスト
    attentionDataDelete({
      url: '/manage/users/app/limping/cancel',
      params: {
        name: data?.result.name,
        rubi: data?.result.rubi,
        birth_date: data?.result.birth_date,
      },
    }, {
      onSuccess: () => {
        history.push('/admin/limping');
      },
      onError: error => {
        setTitle("確認");
        setForm(false);
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage("不正なリクエストです。");
        }
        // 権限なし
        else if (error.response.status === 403) {
          setMessage("権限がありません。");
        }
        // 見つからない
        else if (error.response.status === 404) {
          setMessage("データが存在しません。");
        }
        // その他
        else {
          setMessage("原因不明のエラーです。");
        }
        show();
      },
    });
  }

  if (!location.state) return <Redirect to="/admin/limping" />;

  return (
    <>
      <section className="l-section">
        <div className="u-titlebar">
          <div className="titlebar-left">
            {status === 'success' ? (
              <Typography variant="page">{data?.result.name}</Typography>
            ) : (
              <ContentLoader
                speed={2}
                width={120}
                height={38}
                viewBox="0 0 120 38"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width="120" height="38" />
              </ContentLoader>
            )}
          </div>
          <div className="titlebar-right">
            {status === 'success' && <Button variant="warning" className="titlebar-button" onClick={handleConfirmModalOpen}>削除</Button>}
          </div>
        </div>
        <Frame>
          <table className="u-table-column">
            <tbody>
              <tr>
                <th style={{ width: '180px' }}>名前</th>
                <td>{data?.result.name}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>フリガナ</th>
                <td>{data?.result.rubi}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>生年月日</th>
                <td>{status === 'success' && moment(data?.result.birth_date).format('YYYY年MM月DD日')}</td>
              </tr>
            </tbody>
          </table>
        </Frame>
        {data?.result.histories?.map(history => (
          <React.Fragment key={`eval_history_${history.id}`}>
            <div className="u-titlebar">
              <Typography variant="page">{history.account_company.name}での評価</Typography>
              <Button variant="minimum" className="titlebar-button" onClick={() => handleEditModalOpen(history.id, history.free_comment)}>編集</Button>
            </div>
            <Frame>
              <table className="u-table-column">
                <tbody>
                  <tr>
                    <th style={{ width: '180px' }}>フリーコメント</th>
                    <td>
                      <pre>{history.free_comment}</pre>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Frame>
            <Typography variant="info">
              編集履歴：{history.account_admin ? `${history.account_admin.name} ${moment(history.created_at).format('YYYY/MM/DD HH:mm')}` : 'なし'}
            </Typography>
          </React.Fragment>
        ))}
      </section>
      <Portal>
        <Dialog
          title={title}
          message={msg}
          handleModalClose={hide}
          handleExecuteClick={modalMode === 'delete' ? handleDeleteClick : onUserCommentEdit}
          isShow={isShow}
          form={isForm}
          editor={modalMode === 'edit'}
          isExecuting={modalMode === 'delete' ? isDeleting : isUpdating}
          />
      </Portal>
    </>
  );
}

export default Detail;
