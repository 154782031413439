import React, { useEffect } from 'react';
import clsx from 'clsx';
import Chart from 'chart.js';

const Radar = React.forwardRef(function Radar(props, ref) {
  const { data, className, ...other } = props;

  useEffect(() => {
    var ctx = document.getElementById('radar').getContext('2d');
    var chart = new Chart(ctx, {
      type: 'radar',
      data: {
        labels: ['基本的事項', '関係法令', '車両等誘導', '応急処置', 'その他'],
        datasets: [{
          label: '最新',
          backgroundColor: 'rgba(255, 205, 86, .2)',
          borderColor: '#FFCD56',
          pointBackgroundColor: '#FFCD56',
          data: [
            data?.latest['1'],
            data?.latest['2'],
            data?.latest['3'],
            data?.latest['4'],
            data?.latest['5'],
          ],
        }, {
          label: '初回',
          backgroundColor: 'rgba(255, 168, 68, .2)',
          borderColor: '#FFA844',
          pointBackgroundColor: '#FFA844',
          data: [
            data?.first['1'],
            data?.first['2'],
            data?.first['3'],
            data?.first['4'],
            data?.first['5'],
          ],
        }],
      },
      options: {
        animation: true,
        showTooltips: false,
        legend: {
          display: false,
        },
        responsive: true,
        scale: {
          display: true,
          pointLabels: {
            fontSize: 15,
            fontColor: '#231815',
          },
          ticks: {
            display: true,
            fontSize: 12,
            fontColor: '#707070',
            maxTicksLimit: 6,
            min: 0,
            max: 5,
            beginAtZero: true,
          },
          gridLines: {
            display: true,
            color: '#EAEAEA',
          },
        },
      },
    });
  }, [data]);

  return (
    <div ref={ref} className={clsx('u-radar', className)} {...other}>
      <canvas id="radar" width="400" height="400"></canvas>
      <ul className="radar-meta">
        <li className="radar-text">初回</li>
        <li className="radar-text">最新</li>
      </ul>
    </div>
  );
});

export default Radar;
