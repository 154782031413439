import React from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { IconSignout } from '~/icons';
import {
  Typography,
  Breadcrumb,
  Button,
} from '~/components';
import { routes_admin, routes_customer } from '~/routes';
import { request, converter } from '~/utils';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';

function Template({ children, className, ...other }) {
  const history = useHistory();
  const tokenKey = converter(process.env.REACT_APP_STORAGEKEY_ADMIN, process.env.REACT_APP_STORAGEKEY_CUSTOMER);
  const accountKey = converter(process.env.REACT_APP_ACCOUNT_DATA_ADMIN, process.env.REACT_APP_ACCOUNT_DATA_CUSTOMER);
  const redirectPath = converter('/admin/signin', '/signin');
  const routes = converter(routes_admin, routes_customer);
  const account = JSON.parse(localStorage.getItem(accountKey));
  const { breadcrumbs } = useBreadcrumbsContext();

  /**
   * ログアウトボタンクリックのイベントハンドラ
   * - 永続トークンを破棄
   * - サインインページに遷移
   */
  function handleSignOut() {
    const accessToken = localStorage.getItem(tokenKey);
    const headers = { Authorization: `Bearer ${accessToken}` };
    request.get(`/manage/logout`, { headers }).then(() => {
      localStorage.removeItem(tokenKey);
      localStorage.removeItem(accountKey);
      history.push(redirectPath);
    });
  }

  return (
    <div className="l-row">
      <nav className="l-navbar">
        <div className="u-logo">
          <h1>警備マニア</h1>
          <span>管理システム</span>
          <hr className="logo-divider" size="1" noshade="true" />
        </div>
        <ul className="u-menubar">
          {routes.map(route => {
            if (!account?.super && route.permissions === 'super') return false;
            return (
              <li key={route.title} className="menubar-item">
                <NavLink to={route.path} activeClassName="is-current" disabled={true} className="menubar-link">
                  <route.icon />
                  <span>{route.title}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="l-contents">
        <header className="l-titlebar">
          <Typography variant="account">{account?.name} 様</Typography>
          <Button variant="outline" onClick={handleSignOut}>
            <IconSignout />
            ログアウト
          </Button>
        </header>
        <div className="l-toolbar">
          <Breadcrumb>
            {breadcrumbs.map((breadcrumb, index) => <div key={`${breadcrumb}_${index}`} className="breadcrumb-item">{breadcrumb}</div>)}
          </Breadcrumb>
        </div>
        <main className="l-main">
          <div className="l-page">
            {children}
          </div>
          <footer className="l-footer">
            <Typography variant="copyright">&copy; Copyright SECURITY SERVICE MIRAIZ Inc. All rights reserved.</Typography>
          </footer>
        </main>
      </div>
    </div>
  );
}

export default Template;