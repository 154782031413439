import React from 'react';
import { Redirect } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { converter } from '~/utils';

export default function withAuthenticator(Component) {
  class AuthenticatedComponent extends React.Component {

    constructor(props) {
      super(props);
      this.tokenKey = converter(process.env.REACT_APP_STORAGEKEY_ADMIN, process.env.REACT_APP_STORAGEKEY_CUSTOMER);
      this.redirectPath = converter('/admin/signin', '/signin');
      // トークン有効性の真偽値
      this.invalid = false;
      // ローカルストレージ取得
      this.accessToken = localStorage.getItem(this.tokenKey);
      // トークンをデコードして格納
      const decoded = jwt.decode(this.accessToken);
      // 現在時刻（秒）を取得
      const currentTime = new Date().getTime() / 1000;
      // jwt判定
      if (!decoded) {
        // トークンエラー
        localStorage.removeItem(this.tokenKey);
        this.invalid = false;
      } else {
        if (currentTime > decoded.exp) {
          // 有効期限切れ
          localStorage.removeItem(this.tokenKey);
          this.invalid = false;
        } else {
          // トークン有効
          this.invalid = true;
        }
      }
    }

    render() {
      return this.invalid ? <Component accessToken={this.accessToken} {...this.props} /> : <Redirect to={this.redirectPath} />;
    }
  }

  return AuthenticatedComponent;
}