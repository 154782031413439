import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import usePortal from 'react-cool-portal';
import {
  Typography,
  Button,
  Frame,
  Form,
  Input,
  Dialog,
} from '~/components';
import {
  IconInfo,
  IconLoader,
} from '~/icons';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { useMutant } from '~/hooks';

function Confirm({ history, location }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { handleSubmit, register } = useForm();
  const [infoPosting, { isLoading }] = useMutant();
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false,
  });
  // ダイアログ用
  const [msg, setMessage] = useState('');

  /**
   * 送信ボタンクリック時のお知らせ送信ハンドラ
   */
  function handlePost({ to, account_company_id, title, comment }) {
    // リクエスト中は抜ける
    if (isLoading) return;
    // お知らせ投稿リクエスト
    infoPosting({
      url: '/manage/informations/new',
      params: {
        to: Number(to), // 0: アプリ, 1: 企業, 2: 企業ユーザー
        account_company_id: Number(account_company_id), // 0: 全ユーザー, 1~: 指定ユーザー
        title,
        comment,
      },
    }, {
      onSuccess: () => {
        sessionStorage.removeItem('merisessqts_admin_infoFormData');
        history.push({
          pathname: `/admin/information`,
          state: {
            tabIndex: 1,
          },
        });
      },
      onError: error => {
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage('不正なリクエストです。');
        }
        // その他
        else {
          setMessage('原因不明のエラーです。');
        }
        show();
      }
    });
  }

  /**
   * 入力画面に戻る
   */
  function goBack() {
    history.replace({
      pathname: `/admin/information/create`,
      state: params,
    });
  }

  useEffect(() => {
    setBreadcrumb(['お知らせ', '新規作成', '確認']);
  }, [setBreadcrumb]);

  if (!location.state) return <Redirect to="/admin/information/create" />;
  const { params, users } = location.state;

  return (
    <>
      <section className="l-section">
        <div className="u-titlebar">
          <Typography variant="page">新規作成</Typography>
        </div>
        <Form noValidate onSubmit={handleSubmit(handlePost)}>
          <Frame>
            <table className="u-table-column">
              <tbody>
                <tr>
                  <th style={{ width: '180px' }}>投稿先</th>
                  <td>{params.to === '0' ? 'アプリユーザー' : (params.to === '1' && params.account_company_id !== '0' ? users.filter(user => user.id === Number(params.account_company_id))[0].name : '全ての企業')}</td>
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>タイトル</th>
                  <td>{params.title}</td>
                </tr>
                <tr>
                  <th style={{ verticalAlign: 'top', width: '180px' }}>内容</th>
                  <td>
                    <pre>{params.comment}</pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </Frame>
          <Input
            type="hidden"
            name="to"
            value={params.to}
            ref={register}
          />
          <Input
            type="hidden"
            name="account_company_id"
            value={params.account_company_id}
            ref={register}
          />
          <Input
            type="hidden"
            name="title"
            value={params.title}
            ref={register}
          />
          <Input
            type="hidden"
            name="comment"
            value={params.comment}
            ref={register}
          />
          <div className="u-buttons">
            <Button variant="secondary" className="buttons-item" onClick={goBack}>戻る</Button>
            <Button type="submit" variant="primary" className="buttons-item">
              {isLoading ? <IconLoader className="a-spinner" /> : '送信'}
            </Button>
          </div>
        </Form>
      </section>
      <Portal>
        <Dialog
          title="確認"
          message={msg}
          handleModalClose={hide}
          isShow={isShow}
        />
      </Portal>
    </>
  );
}

export default Confirm;
