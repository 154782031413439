import React, { useEffect, useState } from 'react';
import {
  Typography,
  Frame,
  Button,
  Pagination,
} from '~/components';
import { IconAngleRight } from '~/icons';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { useRead } from '~/hooks';

function Company({ history, match }) {
  const [page, setPage] = useState(1);
  const { setBreadcrumb } = useBreadcrumbsContext();

  /**
   * 利用企業一覧取得
   */
  const { data, refetch } = useRead(`admin_company_list`, `/manage/users/company/index`, {
    order: 1,
    limit: 20,
    offset: 20 * (page - 1),
  }, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  /**
   * 詳細ページに遷移
   */
  function goDetail(result) {
    history.push({
      pathname: `${match.url}/detail/${result.id}`,
      state: result,
    });
  }

  /**
   * 新規作成ページに遷移
   */
  function goCreate() {
    history.push(`${match.url}/create`);
  }

  useEffect(() => {
    setBreadcrumb(['利用企業管理']);
  }, [setBreadcrumb]);

  // ページ選択
  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">利用企業管理</Typography>
        <Button variant="minimum" className="titlebar-button" onClick={goCreate}>新規</Button>
      </div>
      <Frame>
        <table className="u-table-row">
          <thead>
            <tr>
              <Typography variant="left" element="th" style={{ width: `${(180 / 916) * 100}%` }}>会社名</Typography>
              <Typography variant="left" element="th">メールアドレス</Typography>
              <Typography variant="left" element="th">上限ユーザ数</Typography>
            </tr>
          </thead>
          <tbody>
            {data?.results.map(result => {
              const limitOver = result.user_limit <= result.active_user_count;
              return (
                <tr key={`company_list_${result.id}`} onClick={() => goDetail(result)}>
                  <td>{result.name}</td>
                  <td>{result.email}</td>
                  <td style={{ color: limitOver ? 'red' : 'darkgreen' }}>
                    {result.active_user_count}/{result.user_limit}人
                    <Button variant="icon" className="table-button">
                      <IconAngleRight />
                    </Button>
                  </td>
                </tr>
                )
              })
            }
          </tbody>
        </table>
      </Frame>
      <Pagination
        page={page}
        limit={20}
        total={data?.count}
        onSelect={(page) => setPage(page)}
        />
    </section>
  );
}

export default Company;
