import React, { useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import {
  Typography,
  Frame,
  Button,
  Pagination,
} from '~/components';
import { IconAngleRight } from '~/icons';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { useRead } from '~/hooks';

function Question({ history, match }) {
  const [page, setPage] = useState(1);
  const { setBreadcrumb } = useBreadcrumbsContext();
  const sanitizeConfig = {
    allowedTags: ['span'],
    allowedAttributes: {
      span: ['title', 'comment', 'url', 'preference'],
    },
  };

  /**
   * 問設一覧取得
   */
  const { status, data, refetch } = useRead(`question_list`, `/manage/questions/index`, {
    order: 1,
    limit: 20,
    offset: 20 * (page - 1),
  }, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  /**
   * 詳細ページに遷移
   */
  function goDetail(result) {
    history.push({
      pathname: `${match.url}/detail/${result.id}`,
      state: result,
    });
  }

  /**
   * 新規作成ページに遷移
   */
  function goCreate() {
    history.push({
      pathname: `${match.url}/create`,
    });
  }

  useEffect(() => {
    setBreadcrumb(['設問管理']);
  }, [setBreadcrumb]);

  // ページ選択
  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">設問管理</Typography>
        <Button variant="minimum" className="titlebar-button" onClick={goCreate}>新規</Button>
      </div>
      <Frame>
        <table className="u-table-row">
          <thead>
            <tr>
              <Typography variant="left" element="th" style={{ width: `${(180 / 916) * 100}%` }}>カテゴリー</Typography>
              <Typography variant="left" element="th">問題文</Typography>
            </tr>
          </thead>
          <tbody>
            {data?.results.map(result => (
              <tr key={`question_list_${result.id}`} onClick={() => goDetail(result)}>
                <td>{result.question_category?.name}</td>
                <td>
                  <div dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(status === 'success' ? result.question_html : '', sanitizeConfig)
                  }} />
                  <Button variant="icon" className="table-button">
                    <IconAngleRight />
                  </Button>
                </td>
              </tr>
              ))
            }
          </tbody>
        </table>
      </Frame>
      <Pagination
        page={page}
        limit={20}
        total={data?.count}
        onSelect={(page) => setPage(page)}
        />
    </section>
  );
}

export default Question;
