import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import sanitizeHtml from 'sanitize-html';
import usePortal from 'react-cool-portal';
import {
  Typography,
  Button,
  Frame,
  Form,
  Input,
  Dialog,
} from '~/components';
import { IconLoader } from '~/icons';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { useMutant } from '~/hooks';

function Confirm({ history, location }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { handleSubmit, register } = useForm();
  const [questionPost, { isLoading }] = useMutant();
  const categories = ['基本的事項', '関係法令', '車両等誘導', '応急処置', 'その他'];
  const sanitizeConfig = {
    allowedTags: ['span'],
    allowedAttributes: {
      span: ['title', 'comment', 'url', 'preference'],
    },
  };
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false,
  });
  // ダイアログ用
  const [msg, setMessage] = useState('');

  /**
   * Submitイベント
   */
  function onSubmit() {
    // リクエスト中は抜ける
    if (isLoading) return;

    const params = new FormData();

    if (!!location.state.id) params.append('id', Number(location.state.id));
    params.append('question_category_id', Number(location.state.question_category_id));
    params.append('question_html', location.state.question_html);
    params.append('question_sound', location.state.question_sound[0]);
    params.append('answer_1_html', location.state.answer_1_html);
    params.append('answer_1_sound', location.state.answer_1_sound[0]);
    params.append('answer_2_html', location.state.answer_2_html);
    params.append('answer_2_sound', location.state.answer_2_sound[0]);
    params.append('answer_3_html', location.state.answer_3_html);
    params.append('answer_3_sound', location.state.answer_3_sound[0]);
    params.append('answer_4_html', location.state.answer_4_html);
    params.append('answer_4_sound', location.state.answer_4_sound[0]);
    params.append('answer_5_html', location.state.answer_5_html);
    params.append('answer_5_sound', location.state.answer_5_sound[0]);
    params.append('answer_correct_number', Number(location.state.answer_correct_number));
    params.append('answer_explanation_html', location.state.answer_explanation_html);
    params.append('answer_explanation_sound', location.state.answer_explanation_sound[0]);

    // 設問作成更新リクエスト
    questionPost({
      url: !!location.state.id ? '/manage/questions/update' : '/manage/questions/new',
      params,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }, {
      onSuccess: () => {
        sessionStorage.removeItem('merisessqts_admin_questionFormData');
        sessionStorage.removeItem('merisessqts_admin_questionFormData_edit');
        history.push('/admin/question');
      },
      onError: error => {
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage('不正なリクエストです。');
        }
        // 権限なし
        else if (error.response.status === 403) {
          setMessage('権限がありません。');
        }
        // 見つからない
        else if (error.response.status === 404) {
          setMessage('設問が存在しません。');
        }
        // その他
        else {
          setMessage('原因不明のエラーです。');
        }
        show();
      },
    });
  }

  /**
   * 入力画面に戻る
   */
  function goBack() {
    history.goBack();
  }

  useEffect(() => {
    setBreadcrumb(['設問管理', '確認']);
  }, [setBreadcrumb]);

  if (!location.state) return <Redirect to="/admin/question" />;

  return (
    <>
      <section className="l-section">
        <div className="u-titlebar">
          <Typography variant="page">確認</Typography>
        </div>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="caption">カテゴリー</Typography>
          <Frame>
            <table className="u-table-column">
              <tbody>
                <tr>
                  <th style={{ width: '180px' }}>カテゴリー</th>
                  <td>{categories[Number(location.state.question_category_id) - 1]}</td>
                </tr>
              </tbody>
            </table>
          </Frame>
          <hr className="u-separate-offset" noshade="true" />
          <Typography variant="caption">問題</Typography>
          <Frame>
            <table className="u-table-column">
              <tbody>
                <tr>
                  <th style={{ width: '180px' }}>問題</th>
                  <td dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(location.state.question_html, sanitizeConfig)
                  }} />
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>音声ファイル</th>
                  <td>{!!location.state.question_sound.length && location.state.question_sound[0].name}</td>
                </tr>
              </tbody>
            </table>
          </Frame>
          <Frame>
            <table className="u-table-column">
              <tbody>
                <tr>
                  <th style={{ width: '180px' }}>正解</th>
                  <td dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(location.state[`answer_${location.state.answer_correct_number}_html`], sanitizeConfig)
                  }} />
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>音声ファイル</th>
                  <td>{!!location.state[`answer_${location.state.answer_correct_number}_sound`].length && location.state[`answer_${location.state.answer_correct_number}_sound`][0].name}</td>
                </tr>
              </tbody>
            </table>
          </Frame>
          {[...Array(5)].map((_, i) => {
            const index = i + 1;
            if (Number(location.state.answer_correct_number) === index) return false;
            return (
              <Frame key={`answers_list_${i}`}>
                <table className="u-table-column">
                  <tbody>
                    <tr>
                      <th style={{ width: '180px' }}>選択肢{index}</th>
                      <td dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(location.state[`answer_${index}_html`], sanitizeConfig)
                      }} />
                    </tr>
                    <tr>
                      <th style={{ width: '180px' }}>音声ファイル</th>
                      <td>{!!location.state[`answer_${index}_sound`].length && location.state[`answer_${index}_sound`][0].name}</td>
                    </tr>
                  </tbody>
                </table>
              </Frame>
            );
          })}
          <hr className="u-separate-offset" noshade="true" />
          <Typography variant="caption">解説</Typography>
          <Frame>
            <table className="u-table-column">
              <tbody>
                <tr>
                  <th style={{ width: '180px' }}>解説</th>
                  <td dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(location.state.answer_explanation_html, sanitizeConfig)
                  }} />
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>音声ファイル</th>
                  <td>{!!location.state.answer_explanation_sound.length && location.state.answer_explanation_sound[0].name}</td>
                </tr>
              </tbody>
            </table>
          </Frame>
          {!!location.state.id && (
            <Input
              type="hidden"
              name="id"
              value={location.state.id}
              ref={register}
            />
          )}
          <div className="u-buttons">
            <Button variant="secondary" className="buttons-item" onClick={goBack}>戻る</Button>
            <Button type="submit" variant="primary" className="buttons-item">
              {isLoading ? <IconLoader className="a-spinner" /> : '送信'}
            </Button>
          </div>
        </Form>
      </section>
      <Portal>
        <Dialog
          title="確認"
          message={msg}
          handleModalClose={hide}
          isShow={isShow}
        />
      </Portal>
    </>
  );
}

export default Confirm;
