import React, { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import SendListPanel from './SendListPanel';
import ReceiveListPanel from './ReceiveListPanel';

function Information({ location, ...props }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const tabIndex = sessionStorage.getItem('tabIndex');

  /**
   * タブUI切り替えイベントハンドラ
   */
  function handleTabSelect(index) {
    sessionStorage.setItem('tabIndex', index);
  }

  useEffect(() => {
    setBreadcrumb(['お知らせ']);
  }, [setBreadcrumb]);

  return (
    <Tabs defaultIndex={tabIndex !== null ? Number(tabIndex) : 0} onSelect={handleTabSelect}>
      <TabList>
        <Tab>受信BOX</Tab>
        <Tab>送信BOX</Tab>
      </TabList>
      <TabPanel>
        <ReceiveListPanel {...props} />
      </TabPanel>
      <TabPanel>
        <SendListPanel {...props} />
      </TabPanel>
    </Tabs>
  );
}

export default Information;
