import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query-devtools';
import { withAuthenticator, Template } from '~/components';
import { AdminSignin, CustomerSignin } from '~/pages';
import { routes_admin, routes_customer } from '~/routes';
import { BreadcrumbsProvider } from '~/contexts/breadcrumbsContext';

function App() {
  /**
   * mapメソッドのコールバック関数
   * access tokenを必要とするページのrouter
   */
  const routes = route => (
    <Route key={route.title} path={route.path} render={({ match }) => (
      <Template>
        <Route path={match.url} component={withAuthenticator(route.component)} exact />
        {'routes' in route && route.routes.map(route => (
          <Route key={route.title} path={match.url + route.path} component={withAuthenticator(route.component)} exact />
        ))}
      </Template>
    )} />
  );

  return (
    <BreadcrumbsProvider>
      <Router>
        <div className="l-container">
          <Switch>
            {/* admin郡のルーター */}
            {routes_admin.map(routes)}
            <Route path="/admin/signin" component={AdminSignin} exact />
            {/* customer郡のルーター */}
            {routes_customer.map(routes)}
            <Route path="/signin" component={CustomerSignin} exact />
            {/* リダイレクト郡のルーター */}
            <Redirect from="/admin" to="/admin/study" />
            <Redirect from="/" to="/study" />
          </Switch>
        </div>
      </Router>
      <ReactQueryDevtools />
    </BreadcrumbsProvider>
  );
}

export default App;
