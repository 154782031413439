import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import usePortal from 'react-cool-portal';
import {
  Typography,
  Button,
  Frame,
  Form,
  Input,
  Dialog,
} from '~/components';
import { IconLoader } from '~/icons';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { useMutant, useUpdate } from '~/hooks';

function Confirm({ history, location }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { handleSubmit, register } = useForm();
  const [userCreate, { isLoading: isLoadingCreate }] = useMutant();
  const [userUpdate, { isLoading: isLoadingUpdate }] = useUpdate();
  const gender = ['未指定', '男性', '女性', 'その他'];
  const prefectures = ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];
  const isLoading = !!location.state.id ? isLoadingUpdate : isLoadingCreate;
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false,
  });
  // ダイアログ用
  const [msg, setMessage] = useState('');

  /**
   * 新規作成Submitイベント
   */
  function onCreate(values) {
    // リクエスト中は抜ける
    if (isLoading) return;
    // ユーザー作成リクエスト
    userCreate({
      url: '/manage/users/app/new',
      params: {
        email: values.email,
        password: values.password ? values.password : 'password',
        name: values.name,
        rubi: values.rubi,
        gender: Number(values.gender),
        phone: values.phone,
        postal_code: values.postal_code,
        master_area_prefecture_id: Number(values.master_area_prefecture_id),
        address: values.address,
        birth_date: values.birth_date,
        hire_date: values.hire_date,
        license1_1: Number(values.license1_1),
        license1_2: Number(values.license1_2),
        license1_3: Number(values.license1_3),
        license1_4: Number(values.license1_4),
        license2_1: Number(values.license2_1),
        license3_1_1: Number(values.license3_1_1),
        license3_1_2: Number(values.license3_1_2),
        license3_1_3: Number(values.license3_1_3),
        license3_1_4: Number(values.license3_1_4),
        license3_2_1: Number(values.license3_2_1),
        license3_2_2: Number(values.license3_2_2),
        license3_2_3: Number(values.license3_2_3),
        license3_2_4: Number(values.license3_2_4),
        license3_3_1: Number(values.license3_3_1),
        license3_3_2: Number(values.license3_3_2),
        license3_3_3: Number(values.license3_3_3),
        license3_3_4: Number(values.license3_3_4),
      },
    }, {
      onSuccess: () => {
        sessionStorage.removeItem('merisessqts_customer_usersFormData_create');
        history.push('/users');
      },
      onError: error => {
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage('不正なリクエストです。');
        }
        // 権限なし
        else if (error.response.status === 403) {
          setMessage('権限がありません。');
        }
        // コンフリクト
        else if (error.response.status === 409) {
          setMessage('ユーザーが既に存在します。');
        }
        // その他
        else {
          setMessage('原因不明のエラーが発生しました。');
        }
        show();
      },
    });
  }

  /**
   * 更新Submitイベント
   */
  function onUpdate(values) {
    // リクエスト中は抜ける
    if (isLoading) return;
    // ユーザー更新リクエスト
    userUpdate({
      url: '/manage/users/app/update',
      params: {
        id: values.id,
        email: values.email,
        password: values.password ? values.password : 'password',
        name: values.name,
        rubi: values.rubi,
        gender: Number(values.gender),
        phone: values.phone,
        postal_code: values.postal_code,
        master_area_prefecture_id: Number(values.master_area_prefecture_id),
        address: values.address,
        birth_date: values.birth_date,
        hire_date: values.hire_date,
        license1_1: Number(values.license1_1),
        license1_2: Number(values.license1_2),
        license1_3: Number(values.license1_3),
        license1_4: Number(values.license1_4),
        license2_1: Number(values.license2_1),
        license3_1_1: Number(values.license3_1_1),
        license3_1_2: Number(values.license3_1_2),
        license3_1_3: Number(values.license3_1_3),
        license3_1_4: Number(values.license3_1_4),
        license3_2_1: Number(values.license3_2_1),
        license3_2_2: Number(values.license3_2_2),
        license3_2_3: Number(values.license3_2_3),
        license3_2_4: Number(values.license3_2_4),
        license3_3_1: Number(values.license3_3_1),
        license3_3_2: Number(values.license3_3_2),
        license3_3_3: Number(values.license3_3_3),
        license3_3_4: Number(values.license3_3_4),
      },
    }, {
      onSuccess: () => {
        sessionStorage.removeItem('merisessqts_customer_usersFormData_edit');
        history.push('/users');
      },
      onError: error => {
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage('不正なリクエストです。');
        }
        // 権限なし
        else if (error.response.status === 403) {
          setMessage('権限がありません。');
        }
        // 見つからない
        else if (error.response.status === 404) {
          setMessage('ユーザーが存在しません。');
        }
        // コンフリクト
        else if (error.response.status === 409) {
          setMessage('ユーザーが既に存在します。');
        }
        // その他
        else {
          setMessage('原因不明のエラーが発生しました。');
        }
        show();
      },
    });
  }

  /**
   * 入力画面に戻る
   */
  function goBack() {
    history.goBack();
  }

  useEffect(() => {
    setBreadcrumb(['ユーザー管理', '確認']);
  }, [setBreadcrumb]);

  if (!location.state) return <Redirect to="/users" />;

  return (
    <>
      <section className="l-section">
        <div className="u-titlebar">
          <Typography variant="page">確認</Typography>
        </div>
        <Form noValidate onSubmit={handleSubmit(!!location.state.id ? onUpdate : onCreate)}>
          <Frame>
            <table className="u-table-column">
              <tbody>
                <tr>
                  <th style={{ width: '180px' }}>名前</th>
                  <td>{`${location.state.l_name} ${location.state.f_name}`}</td>
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>フリガナ</th>
                  <td>{`${location.state.l_kana} ${location.state.f_kana}`}</td>
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>メールアドレス</th>
                  <td>{location.state.email}</td>
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>生年月日</th>
                  <td>
                    {
                      (() => {
                        if (location.state.birth_date_year === '') {
                          return '';
                        }
                        return `${location.state.birth_date_year}年${location.state.birth_date_month}月${location.state.birth_date_day}日`;
                      })()
                    }
                  </td>
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>性別</th>
                  <td>{gender[location.state.gender]}</td>
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>電話番号</th>
                  <td>{location.state.phone}</td>
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>郵便番号</th>
                  <td>{location.state.postal_code}</td>
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>住所</th>
                  <td>{!!location.state.master_area_prefecture_id && prefectures[location.state.master_area_prefecture_id - 1]} {location.state.address}</td>
                </tr>
                <tr>
                  <th style={{ width: '180px' }}>入社日</th>
                  <td>{`${location.state.hire_date_year}年${location.state.hire_date_month}月${location.state.hire_date_day}日`}</td>
                </tr>
                <tr>
                  <th style={{ verticalAlign: 'top', width: '180px' }}>所持資格</th>
                  <td>
                    {!!location.state.license1_1 && <>指導教育責任者１号警備<br /></>}
                    {!!location.state.license1_2 && <>指導教育責任者２号警備<br /></>}
                    {!!location.state.license1_3 && <>指導教育責任者３号警備<br /></>}
                    {!!location.state.license1_4 && <>指導教育責任者４号警備<br /></>}
                    {!!location.state.license2_1 && <>機械警備業務管理者<br /></>}
                    {!!location.state.license3_1_1 && <>１号警備 施設警備業務検定１級<br /></>}
                    {!!location.state.license3_1_2 && <>１号警備 施設警備業務検定２級<br /></>}
                    {!!location.state.license3_1_3 && <>１号警備 空港保安警備業務検定１級<br /></>}
                    {!!location.state.license3_1_4 && <>１号警備 空港保安警備業務検定２級<br /></>}
                    {!!location.state.license3_2_1 && <>２号警備 交通誘導警備業務検定１級<br /></>}
                    {!!location.state.license3_2_2 && <>２号警備 交通誘導警備業務検定２級<br /></>}
                    {!!location.state.license3_2_3 && <>２号警備 雑踏警備業務検定１級<br /></>}
                    {!!location.state.license3_2_4 && <>２号警備 雑踏警備業務検定２級<br /></>}
                    {!!location.state.license3_3_1 && <>３号警備 貴重品運搬警備業務検定１級<br /></>}
                    {!!location.state.license3_3_2 && <>３号警備 貴重品運搬警備業務検定２級<br /></>}
                    {!!location.state.license3_3_3 && <>３号警備 核燃料輸送警備業務検定１級<br /></>}
                    {!!location.state.license3_3_4 && <>３号警備 核燃料輸送警備業務検定２級<br /></>}
                  </td>
                </tr>
              </tbody>
            </table>
          </Frame>
          {!!location.state.id && (
            <Input
              type="hidden"
              name="id"
              value={location.state.id}
              ref={register}
            />
          )}
          <Input
            type="hidden"
            name="name"
            value={`${location.state.l_name} ${location.state.f_name}`}
            ref={register}
          />
          <Input
            type="hidden"
            name="rubi"
            value={`${location.state.l_kana} ${location.state.f_kana}`}
            ref={register}
          />
          <Input
            type="hidden"
            name="email"
            value={location.state.email}
            ref={register}
          />
          <Input
            type="hidden"
            name="password"
            value={location.state.password}
            ref={register}
          />
          <Input
            type="hidden"
            name="gender"
            value={location.state.gender}
            ref={register}
          />
          <Input
            type="hidden"
            name="phone"
            value={location.state.phone}
            ref={register}
          />
          <Input
            type="hidden"
            name="postal_code"
            value={location.state.postal_code}
            ref={register}
          />
          <Input
            type="hidden"
            name="master_area_prefecture_id"
            value={location.state.master_area_prefecture_id}
            ref={register}
          />
          <Input
            type="hidden"
            name="address"
            value={location.state.address}
            ref={register}
          />
          <Input
            type="hidden"
            name="address"
            value={location.state.address}
            ref={register}
          />
          <Input
            type="hidden"
            name="birth_date"
            value={
              (() => {
                if (location.state.birth_date_year === '') {
                  return '';
                }
                return `${location.state.birth_date_year}-${location.state.birth_date_month}-${location.state.birth_date_day}`;
              })()
            }
            ref={register}
          />
          <Input
            type="hidden"
            name="hire_date"
            value={`${location.state.hire_date_year}-${location.state.hire_date_month}-${location.state.hire_date_day}`}
            ref={register}
          />
          <Input
            type="hidden"
            name="license1_1"
            value={Number(location.state.license1_1)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license1_2"
            value={Number(location.state.license1_2)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license1_3"
            value={Number(location.state.license1_3)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license1_4"
            value={Number(location.state.license1_4)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license2_1"
            value={Number(location.state.license2_1)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_1_1"
            value={Number(location.state.license3_1_1)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_1_2"
            value={Number(location.state.license3_1_2)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_1_3"
            value={Number(location.state.license3_1_3)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_1_4"
            value={Number(location.state.license3_1_4)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_2_1"
            value={Number(location.state.license3_2_1)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_2_2"
            value={Number(location.state.license3_2_2)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_2_3"
            value={Number(location.state.license3_2_3)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_2_4"
            value={Number(location.state.license3_2_4)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_3_1"
            value={Number(location.state.license3_3_1)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_3_2"
            value={Number(location.state.license3_3_2)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_3_3"
            value={Number(location.state.license3_3_3)}
            ref={register}
          />
          <Input
            type="hidden"
            name="license3_3_4"
            value={Number(location.state.license3_3_4)}
            ref={register}
          />
          <div className="u-buttons">
            <Button variant="secondary" className="buttons-item" onClick={goBack}>戻る</Button>
            <Button type="submit" variant="primary" className="buttons-item">
              {isLoading ? <IconLoader className="a-spinner" /> : '送信'}
            </Button>
          </div>
        </Form>
      </section>
      <Portal>
        <Dialog
          title="確認"
          message={msg}
          handleModalClose={hide}
          isShow={isShow}
        />
      </Portal>
    </>
  );
}

export default Confirm;
