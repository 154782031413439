import React, { useEffect } from 'react';
import clsx from 'clsx';
import Chart from 'chart.js';
import moment from 'moment';

const PassBarChart = React.forwardRef(function BarChart(props, ref) {
  const { data, className, ...other } = props;

  useEffect(() => {
    var ctx = document.getElementById('bar').getContext('2d');
    var chart = new Chart(ctx, {
      data: {
        labels: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        datasets: [{
          type: 'bar',
          label: '合格者',
          backgroundColor: '#F0B21E',
          borderColor: '#F0B21E',
          data: [
            data?.month.practice_exam.total1,
            data?.month.practice_exam.total2,
            data?.month.practice_exam.total3,
            data?.month.practice_exam.total4,
            data?.month.practice_exam.total5,
            data?.month.practice_exam.total6,
            data?.month.practice_exam.total7,
            data?.month.practice_exam.total8,
            data?.month.practice_exam.total9,
            data?.month.practice_exam.total10,
            data?.month.practice_exam.total11,
            data?.month.practice_exam.total12,
          ],
        },
        {
          type: 'line',
          label: '受験者',
          fill: false,
          // backgroundColor: '#F0B21E',
          borderColor: '#F3AB58',
          pointRadius: 5,
          pointHoverRadius: 10,
          pointBorderWidth: 2,
          pointBackgroundColor: "#fff",
          lineTension: false,
          data: [
            data?.month.practice_exam.pass1,
            data?.month.practice_exam.pass2,
            data?.month.practice_exam.pass3,
            data?.month.practice_exam.pass4,
            data?.month.practice_exam.pass5,
            data?.month.practice_exam.pass6,
            data?.month.practice_exam.pass7,
            data?.month.practice_exam.pass8,
            data?.month.practice_exam.pass9,
            data?.month.practice_exam.pass10,
            data?.month.practice_exam.pass11,
            data?.month.practice_exam.pass12,
          ],
        }],
      },
      options: {
        animation: false,
        legend: {
          display: false,
        },
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: '#333',
              fontSize: 12,
            },
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: '#E3E3E3',
              drawTicks: false,
              drawBorder: false,
            },
            ticks: {
              autoSkip: true,
              fontColor: '#333',
              fontSize: 12,
              suggestedMin: 0,
              suggestedMax: 50,
            },
          }],
        },
      },
    });
  }, [data]);

  return (
    <div ref={ref} className={clsx('u-bar', className)} {...other}>
      <canvas id="bar"></canvas>
      <p className="bar-label">人</p>
      <ul className="bar-meta">
        <li className="bar-text">受験者</li>
        <li className="bar-text">合格者</li>
      </ul>
    </div>
  );
});

export default PassBarChart;
