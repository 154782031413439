import React, { useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import useFormPersist from 'react-hook-form-persist';
import clsx from 'clsx';
import {
  Typography,
  Button,
  Input,
  Form,
  Field,
} from '~/components';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { IconInfo } from '~/icons';

function Detail({ location, history }) {
  const formData = JSON.parse(sessionStorage.getItem('merisessqts_admin_companyFormData_edit'));
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { handleSubmit, register, errors, setValue, watch } = useForm({
    defaultValues: {
      id: location.staet ? location.state.id : '',
      name: formData ? formData.name : location.state.name,
      email: formData ? formData.email : location.state.email,
      password: '',
      phone: formData ? formData.phone : location.state.phone,
      user_limit: formData ? formData.user_limit : location.state.user_limit,
    },
  });

  /**
   * フォームデータの永続化
   */
  const { clear } = useFormPersist('merisessqts_admin_companyFormData_edit', { watch, setValue }, {
    include: [
      'name',
      'email',
      'phone',
      'user_limit',
    ],
  });

  /**
   * 確認画面に遷移
   */
  function goConfirm(params) {
    history.push({
      pathname: `/admin/company/confirm`,
      state: params,
    });
  }

  /**
   * キャンセルボタンをクリック時のイベントハンドラ
   */
  const handleReset = useCallback(e => {
    e.preventDefault();
    clear();
    history.goBack();
  }, []);

  /**
   * 上限ユーザ数の選択。
   */
  function selectLimit(e) {
    document.getElementById('user_limit').value = e.target.value;
    e.target.value = "";
  };

  useEffect(() => {
    setBreadcrumb(['利用企業管理', location.state ? location.state.name : '詳細', '編集']);
  }, []);

  if (!location.state) return <Redirect to="/admin/company" />;

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">編集</Typography>
      </div>
      <Form noValidate onSubmit={handleSubmit(goConfirm)} onReset={handleReset}>
        <Field label="会社名" required={true}>
          <div className="field-row">
            <Input
              name="name"
              ref={register({
                required: '会社名は必須項目です。',
              })}
              className={clsx('short', errors.name && 'is-error')}
            />
            <ErrorMessage
              name="name"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
          </div>
        </Field>
        <Field label="メールアドレス" required={true}>
          <div className="field-col">
            <Input
              type="email"
              name="email"
              ref={register({
                required: 'メールアドレスは必須項目です。',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'メールアドレス形式に誤りがあります。正しい内容を入力してください。',
                },
              })}
              className={clsx('bob', errors.email && 'is-error')}
            />
            <ErrorMessage
              name="email"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
          </div>
        </Field>
        <Field label="パスワード">
          <div className="field-col">
            <Input
              type="password"
              name="password"
              ref={register({
                minLength: {
                  value: 8,
                  message: 'パスワードは8文字以上である必要があります。',
                },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                  message: 'パスワードは英大文字・英小文字・数字それぞれを最低1文字ずつ含む必要があります。',
                },
              })}
              className={clsx('short', errors.password && 'is-error')}
            />
            <ErrorMessage
              name="password"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
          </div>
        </Field>
        <Field label="電話番号">
          <div className="field-col">
            <Input
              type="tel"
              name="phone"
              ref={register({
                pattern: {
                  value: /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
                  message: '電話番号形式に誤りがあります。正しい内容を入力してください。',
                },
              })}
              className={clsx('short', errors.phone && 'is-error')}
            />
            <ErrorMessage
              name="phone"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
          </div>
        </Field>
        <Field label="上限ユーザ数">
          <div className="field-col">
            <Input
              id="user_limit"
              type="number"
              name="user_limit"
              style={{ maxWidth: '132px', borderRadius: '5px 0px 0px 5px', textAlign: 'end' }}
              ref={register({
                required: '上限ユーザ数は必須項目です。',
                pattern: {
                  value: /^[0-9]{1,3}$/i,
                  message: '上限ユーザ数に誤りがあります。1 ~ 999 を入力してください。',
                },
              })}
              className={clsx('short', errors.user_limit && 'is-error')}
            />
            <select
              style={{ maxWidth: '18px', height: '44px', borderLeft: 'unset', borderTop: '1px solid #BFBFBF', borderRight: '1px solid #BFBFBF', borderBottom: '1px solid #BFBFBF', borderRadius: '0px 5px 5px 0px' }}
              onChange={selectLimit}
            >
              <option value="">上限数を選択</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
              <option value="40">40</option>
              <option value="50">50</option>
            </select>
            <ErrorMessage
              name="user_limit"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
          </div>
        </Field>
        <Input
          type="hidden"
          name="id"
          value={location.state.id}
          ref={register}
        />
        <div className="u-buttons">
          <Button type="reset" variant="secondary" className="buttons-item">キャンセル</Button>
          <Button type="submit" variant="primary" className="buttons-item">確認画面へ</Button>
        </div>
      </Form>
    </section>
  );
}

export default Detail;
