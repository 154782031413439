import React from 'react';

export default ({page, limit, total, onSelect}) => {
  if (!page || !total) {
    return <></>;
  }

  function handleClick(page) {
    if (onSelect) {
      onSelect(page);
    }
  };

  const pageCount = parseInt(total / limit) + (total % limit == 0 ? 0 : 1);

  const sPrev = {
    width: '28px',
    height: '40px',
    border: '1px solid #BFBFBF',
    fontSize: '16px',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  };

  const s = {
    width: sPrev.width,
    height: sPrev.height,
    borderTop: '1px solid #BFBFBF',
    borderRight: '1px solid #BFBFBF',
    borderBottom: '1px solid #BFBFBF',
    fontSize: sPrev.fontSize,
    textAlign: sPrev.textAlign,
    cursor: 'pointer',
    userSelect: sPrev.userSelect,
  };

  const sCurrent = JSON.parse(JSON.stringify(s));
  sCurrent.backgroundColor = 'rgba(0, 0, 0, 0.05)';

  const sCenter = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <ul style={{ display: 'flex', justifyContent: 'center' }}>
        <li className="pagination-item" style={sPrev} onClick={() => {(1 <= page - 1) && handleClick(page - 1)}}><div style={sCenter}>&lt;&lt;</div></li>
        {
          1 <= page - 3 && <li style={s}><div style={sCenter}>…</div></li>
        }
        {
          pageCount - 2 <= page && page <= pageCount && 1 < page - 4 && <li className="pagination-item" style={s} onClick={() => handleClick(page - 4)}><div style={sCenter}>{page - 4}</div></li>
        }
        {
          pageCount - 1 <= page && page <= pageCount && 1 <= page - 3 && <li className="pagination-item" style={s} onClick={() => handleClick(page - 3)}><div style={sCenter}>{page - 3}</div></li>
        }
        {
          1 <= page - 2 && <li className="pagination-item" style={s} onClick={() => handleClick(page - 2)}><div style={sCenter}>{page - 2}</div></li>
        }
        {
          1 <= page - 1 && <li className="pagination-item" style={s} onClick={() => handleClick(page - 1)}><div style={sCenter}>{page - 1}</div></li>
        }
        <li style={sCurrent}><div style={sCenter}>{page}</div></li>
        {
          page + 1 <= pageCount && <li className="pagination-item" style={s} onClick={() => handleClick(page + 1)}><div style={sCenter}>{page + 1}</div></li>
        }
        {
          page + 2 <= pageCount && <li className="pagination-item" style={s} onClick={() => handleClick(page + 2)}><div style={sCenter}>{page + 2}</div></li>
        }
        {
          1 <= page && page <= 2 && page + 3 <= pageCount && <li className="pagination-item" style={s} onClick={() => handleClick(page + 3)}><div style={sCenter}>{page + 3}</div></li>
        }
        {
          1 <= page && page <= 3 && page + 4 < pageCount && <li className="pagination-item" style={s} onClick={() => handleClick(page + 4)}><div style={sCenter}>{page + 4}</div></li>
        }
        {
          3 <= pageCount - page && <li style={s}><div style={sCenter}>…</div></li>
        }
        <li className="pagination-item" style={s} onClick={() => {(page + 1 <= pageCount) && handleClick(page + 1)}}><div style={sCenter}>&gt;&gt;</div></li>
      </ul>
    </div>
  );
};
