import React from 'react';
import clsx from 'clsx';

const Field = React.forwardRef(function Field(props, ref) {
  const {
    className,
    element = 'label',
    variant,
    label = '',
    children,
    required = false,
    ...other
  } = props;
  const Component = element;

  return (
    <Component ref={ref} className={clsx(!variant ? `u-field` : `u-field-${variant}`, className)} {...other}>
      {label && <p className={clsx('field-label', required && 'is-required')}>{label}</p>}
      {children}
    </Component>
  );
});

export default React.memo(Field, (prevProps, nextProps) => false);