import React from 'react';
import clsx from 'clsx';

function Breadcrumb({ className, ...other }) {
  return (
    <div className={clsx('u-breadcrumb', className)} {...other} />
  );
}

export default Breadcrumb;
