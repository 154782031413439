import React, { useEffect, useState } from 'react';
import Rating from 'react-rating';
import { useRead } from '~/hooks';
import {
  Typography,
  Radar,
  PassBarChart,
  AdminBarChart,
  Dialog,
} from '~/components';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import {
  IconClock,
  IconChart,
} from '~/icons';
import usePortal from 'react-cool-portal';

function Detail({ history, match }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false,
  });
  // ダイアログ用
  const [msg, setMessage] = useState("");

  /**
   * 学習状況を取得
   */
  const { data } = useRead(`study_detail_${match.params.id}`, `/manage/study/show`, {
    id: match.params.id,
  }, {
    refetchOnWindowFocus: false,
    retry: false,
    onError: error => {
      // 不正なリクエスト
      if (error.response.status === 400) {
        setMessage("不正なリクエストです。");
      }
      // 権限なし
      else if  (error.response.status === 403) {
        setMessage("権限がありません。");
      }
      // 見つからない
      else if (error.response.status === 404) {
        setMessage("データが存在しません。");
      }
      // その他
      else {
        setMessage("原因不明のエラーです。");
      }
      show();
    },
  });

  useEffect(() => {
    setBreadcrumb(['学習状況', '詳細']);
  }, [setBreadcrumb]);

  return (
    <>
      <section className="l-section">
        <div className="u-profilebar">
          <div className="profilebar-name">
            <Typography variant="page">{data?.result.name}</Typography>
            <Typography variant="ruby">※資格取得者数は延べ人数です。<br/>詳細は各企業にお問い合わせください。</Typography>
          </div>
          <div className="profilebar-col">
            <p className="profilebar-label"><IconClock />従業員数</p>
            <div className="profilebar-score u-fraction">
              <span className="fraction-upper">{data?.result.employee}</span>
              <span className="fraction-suffix">人</span>
            </div>
          </div>
          <div className="profilebar-col">
            <p className="profilebar-label"><IconChart />アクティブ数/アプリ登録者数</p>
            <div className="profilebar-score u-fraction">
              <span className="fraction-upper">{data?.result.active}</span>
              <span className="fraction-suffix">人</span>
              <span className="fraction-border-just">/</span>
              <span className="fraction-upper">{data?.result.register}</span>
              <span className="fraction-suffix">人</span>
            </div>
          </div>
          <div className="profilebar-col">
            <p className="profilebar-label"><IconClock />交通誘導警備業務２級</p>
            <div className="profilebar-score u-fraction">
              <span className="fraction-upper">{data?.result.license3_2_2}</span>
              <span className="fraction-suffix">人 保持</span>
            </div>
          </div>
          <div className="profilebar-col">
            <p className="profilebar-label"><IconClock />交通誘導警備業務１級</p>
            <div className="profilebar-score u-fraction">
              <span className="fraction-upper">{data?.result.license3_2_1}</span>
              <span className="fraction-suffix">人 保持</span>
            </div>
          </div>
        </div>
      </section>
      <section className="l-section">
        <Typography variant="label"><IconChart />テスト成績</Typography>
        <div className="u-leaflet">
          <div className="leaflet-radar">
            <Radar data={data?.result} />
          </div>
          <div className="leaflet-total">
            <p className="leaflet-label">全体の理解度</p>
            <Rating
              className="u-rating"
              readonly
              initialRating={data?.result.rate.total_avg}
              emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2235.259%22%20height%3D%2233.562%22%20viewBox%3D%220%200%2035.259%2033.562%22%3E%20%3Cpath%20id%3D%22_ionicons_svg_md-star%22%20d%3D%22M71.63%2C90.98l10.895%2C6.583L79.642%2C85.146%2C89.259%2C76.8l-12.678-1.09L71.63%2C64%2C66.677%2C75.706%2C54%2C76.8l9.617%2C8.349L60.734%2C97.562Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
              fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2235.259%22%20height%3D%2233.562%22%20viewBox%3D%220%200%2035.259%2033.562%22%3E%20%3Cpath%20id%3D%22_ionicons_svg_md-star%22%20d%3D%22M71.63%2C90.98l10.895%2C6.583L79.642%2C85.146%2C89.259%2C76.8l-12.678-1.09L71.63%2C64%2C66.677%2C75.706%2C54%2C76.8l9.617%2C8.349L60.734%2C97.562Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
            />
          </div>
          <div className="leaflet-category">
            <p className="leaflet-label">カテゴリ別理解度</p>
            <div className="u-category">
              <p className="category-label">基本的事項</p>
              <Rating
                className="u-rating-small"
                readonly
                initialRating={data?.result.rate.rate1}
                emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
              />
            </div>
            <div className="u-category">
              <p className="category-label">関係法令</p>
              <Rating
                className="u-rating-small"
                readonly
                initialRating={data?.result.rate.rate2}
                emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
              />
            </div>
            <div className="u-category">
              <p className="category-label">車両等誘導</p>
              <Rating
                className="u-rating-small"
                readonly
                initialRating={data?.result.rate.rate3}
                emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
              />
            </div>
            <div className="u-category">
              <p className="category-label">応急処置</p>
              <Rating
                className="u-rating-small"
                readonly
                initialRating={data?.result.rate.rate4}
                emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
              />
            </div>
            <div className="u-category">
              <p className="category-label">その他</p>
              <Rating
                className="u-rating-small"
                readonly
                initialRating={data?.result.rate.rate5}
                emptySymbol={<img alt="empty" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%20opacity%3D%220.2%22%2F%3E%3C%2Fsvg%3E" />}
                fullSymbol={<img alt="full" src="data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2215.66%22%20height%3D%2214.885%22%20viewBox%3D%220%200%2015.66%2014.885%22%3E%20%3Cpath%20d%3D%22M61.83%2C75.965l4.839%2C2.919-1.28-5.507%2C4.271-3.7-5.631-.484L61.83%2C64l-2.2%2C5.192L54%2C69.675l4.271%2C3.7-1.28%2C5.507Z%22%20transform%3D%22translate(-54%20-64)%22%20fill%3D%22%23f0b21e%22%2F%3E%3C%2Fsvg%3E" />}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="l-section">
        <Typography variant="label"><IconChart />受験者数と合格者</Typography>
        <PassBarChart data={data?.result} />
      </section>
      <section className="l-section">
        <Typography variant="label"><IconChart />平均学習時間</Typography>
        <AdminBarChart data={data?.result} />
      </section>
      <Portal>
        <Dialog
          title="確認"
          message={msg}
          handleModalClose={hide}
          isShow={isShow}
          />
      </Portal>
    </>
  );
}

export default Detail;
