import { useQuery } from 'react-query';
import { request, converter } from '~/utils';

function useRead(key, url, params = {}, options = {}) {
  return useQuery(key, async () => {
    const token = localStorage.getItem(converter(process.env.REACT_APP_STORAGEKEY_ADMIN, process.env.REACT_APP_STORAGEKEY_CUSTOMER));
    const headers = { Authorization: `Bearer ${token}` };
    const { data } = await request.get(url, {
      headers,
      params,
    });
    return data;
  }, options);
}

export default useRead;