import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Typography,
  Frame,
  Button,
  Pagination,
} from '~/components';
import { IconAngleRight } from '~/icons';
import { useRead } from '~/hooks';

function SendListPanel({ history, match }) {
  const [page, setPage] = useState(1);

  /**
   * お知らせ送信一覧を取得
   */
  const { data, refetch } = useRead(`send_list`, `/manage/informations/index`, {
    order: 0,
    to: 2,
    limit: 20,
    offset: 20 * (page - 1),
  });

  /**
   * 詳細ページに遷移
   */
  function goDetail(result) {
    history.push({
      pathname: `${match.url}/detail`,
      state: result,
    });
  }

  /**
   * 新規作成ページに遷移
   */
  function goCreate() {
    history.push({
      pathname: `${match.url}/create`,
    });
  }

  // ページ選択
  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">お知らせ</Typography>
        <Button variant="minimum" className="titlebar-button" onClick={goCreate}>新規</Button>
      </div>
      <Frame>
        <table className="u-table-row">
          <thead>
            <tr>
              <Typography variant="left" element="th" style={{ width: '170px' }}>投稿日時</Typography>
              <Typography variant="left" element="th" style={{ width: '180px' }}>投稿先</Typography>
              <Typography variant="left" element="th" >タイトル</Typography>
            </tr>
          </thead>
          <tbody>
            {data?.results.map(result => (
              <tr key={`receive_list_${result.id}`} onClick={() => goDetail(result)}>
                <td>{moment(result.created_at).format('YYYY/MM/DD HH:mm')}</td>
                <td>{result.account_user ? result.account_user.name : 'すべてのユーザー'}</td>
                <td>{result.title}
                  <Button variant="icon" className="table-button">
                    <IconAngleRight />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Frame>
      <Pagination
        page={page}
        limit={20}
        total={data?.count}
        onSelect={(page) => setPage(page)}
        />
    </section>
  );
}

export default SendListPanel;
