import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import {
  Typography,
  Button,
  Form,
  Textarea,
  Field,
} from '~/components';
import {
  IconCheck,
  IconLoader,
  IconWarning,
} from '~/icons';

const Dialog = React.forwardRef(function Dialog(props, ref) {
  const {
    title,
    message,
    handleModalClose,
    handleExecuteClick,
    isShow,
    form,
    editor,
    isExecuting,
    modalType,
    content,
  } = props;
  const { handleSubmit, register } = useForm();

  /**
   * エラー用。
   */
  const apiError = () => {
    return (
      <div className={clsx('u-modal', isShow && 'is-show')} tabIndex={-1}>
        <div className="modal-overlay" onClick={handleModalClose} />
        <div className="modal-body">
          <div className="modal-icon">{modalType === 'done' || modalType === 'cancel' ? <IconCheck /> : <IconWarning />}</div>
          <Typography className="modal-title">{title}</Typography>
          <Typography className="modal-text">{message}</Typography>
          <Button className="modal-button" variant="formal" onClick={handleModalClose}>OK</Button>
        </div>
      </div>);
  };

  /**
   * コンテンツ削除用。
   */
  const deleteContent = () => {
    return (
      <div className={clsx('u-modal', isShow && 'is-show')} tabIndex={-1}>
        <div className="modal-overlay" onClick={handleModalClose} />
        <div className="modal-form is-show">
          <div className="modal-icon"><IconWarning /></div>
          <Typography className="modal-title">{title}</Typography>
          <Typography className="modal-text">{message}</Typography>
          <div className="modal-btnbar">
            <Button variant="primary" className="buttons-item" onClick={handleModalClose}>キャンセル</Button>
            <Button variant="secondary" className="buttons-item" onClick={handleExecuteClick}>
              {isExecuting ? <IconLoader className="a-spinner" /> : 'OK'}
            </Button>
          </div>
        </div>
      </div>);
  };

  /**
   * 要注意人物コメント編集用。
   */
  const editLimpingComment = () => {
    return (
      <div className={clsx('u-modal', isShow && 'is-show')} tabIndex={-1}>
        <div className="modal-overlay" onClick={handleModalClose} />
        <div className="modal-form is-show">
          <div className="modal-head">
            <Typography variant="theme">{title}</Typography>
          </div>
          <Form noValidate onSubmit={handleSubmit(handleExecuteClick)}>
            <Field label="フリーコメント" className="u-field-modal">
              <div className="field-col">
                <Textarea
                  placeholder="勤務態度など評価を記載"
                  rows="3"
                  name="retire_comment"
                  ref={register}
                />
                <span className="field-meta">※個人情報を含むコメントは入力しないでください。</span>
              </div>
            </Field>
            <div className="modal-btnbar">
              <Button variant="primary" className="buttons-item" onClick={handleModalClose}>キャンセル</Button>
              <Button type="submit" variant="secondary" className="buttons-item">
                {isExecuting ? <IconLoader className="a-spinner" /> : 'OK'}
              </Button>
            </div>
          </Form>
        </div>
      </div>);
  };

  if (modalType === 'normal' || modalType === 'limping') {
    return (
      <div className={clsx('u-modal', isShow && 'is-show')} tabIndex={-1}>
        <div className="modal-overlay" onClick={handleModalClose} />
        <div className='modal-form is-show'>
          <div className="u-titlebar">
            <Typography className="modal-title">{title}</Typography>
          </div>
          {content}
        </div>
      </div>);
  }

  return (
    !editor ? (!form ? apiError() : deleteContent()) : editLimpingComment()
  );
});

export default Dialog;