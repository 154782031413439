import React from 'react';
import clsx from 'clsx';

const Input = React.forwardRef(function Input(props, ref) {
  const {
    type = 'text',
    name,
    value,
    className,
    onChange,
    variant = 'primary',
    ...other
  } = props;

  return (
    <input
      type={type}
      name={name}
      value={value}
      className={clsx(`input-${variant}`, className)}
      onChange={onChange}
      ref={ref}
      {...other}
    />
  );
});

export default Input;
