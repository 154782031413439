import React from 'react';
import clsx from 'clsx';

function Frame({ className, children, ...other }) {
  return (
    <div className={clsx('u-frame', className)} {...other}>
      <div className="frame-inner">
        {children}
      </div>
    </div>
  );
}

export default Frame;
