import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Typography,
  Button,
  Frame,
  Pagination,
} from '~/components';
import { useRead } from '~/hooks';
import { IconAngleRight } from '~/icons';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';

function Users({ history, match }) {
  const [page, setPage] = useState(1);
  const { setBreadcrumb } = useBreadcrumbsContext();
  const licenses = [
    {
      key: 'license1_1',
      title: '指導教育責任者',
      section: '１号警備',
      level: null,
    },
    {
      key: 'license1_2',
      title: '指導教育責任者',
      section: '２号警備',
      level: null,
    },
    {
      key: 'license1_3',
      title: '指導教育責任者',
      section: '３号警備',
      level: null,
    },
    {
      key: 'license1_4',
      title: '指導教育責任者',
      section: '４号警備',
      level: null,
    },
    {
      key: 'license2_1',
      title: '機械警備業務管理者',
      section: null,
      level: null,
    },
    {
      key: 'license3_1_1',
      title: '施設警備業務検定',
      section: null,
      level: '１級',
    },
    {
      key: 'license3_1_2',
      title: '施設警備業務検定',
      section: '１号警備',
      level: '２級',
    },
    {
      key: 'license3_1_3',
      title: '空港保安警備業務検定',
      section: '１号警備',
      level: '１級',
    },
    {
      key: 'license3_1_4',
      title: '空港保安警備業務検定',
      section: '１号警備',
      level: '２級',
    },
    {
      key: 'license3_2_1',
      title: '交通誘導警備業務検定',
      section: '２号警備',
      level: '１級',
    },
    {
      key: 'license3_2_2',
      title: '交通誘導警備業務検定',
      section: '２号警備',
      level: '２級',
    },
    {
      key: 'license3_2_3',
      title: '雑踏警備業務検定',
      section: '２号警備',
      level: '１級',
    },
    {
      key: 'license3_2_4',
      title: '雑踏警備業務検定',
      section: '２号警備',
      level: '２級',
    },
    {
      key: 'license3_3_1',
      title: '貴重品運搬警備業務検定',
      section: '３号警備',
      level: '１級',
    },
    {
      key: 'license3_3_2',
      title: '貴重品運搬警備業務検定',
      section: '３号警備',
      level: '２級',
    },
    {
      key: 'license3_3_3',
      title: '核燃料輸送警備業務検定',
      section: '３号警備',
      level: '１級',
    },
    {
      key: 'license3_3_4',
      title: '核燃料輸送警備業務検定',
      section: '３号警備',
      level: '２級',
    },
  ];

  /**
   * 企業ユーザー一覧を取得
   */
  const { data, refetch } = useRead(`users_list`, `/manage/users/app/index`, {
    order: 1,
    limit: 20,
    offset: 20 * (page - 1),
  }, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  /**
   * 詳細ページに遷移
   */
  function goDetail(item) {
    history.push({
      pathname: `${match.url}/detail/${item.id}`,
      state: item,
    });
  }

  function goCreate() {
    history.push(`${match.url}/create`);
  }

  useEffect(() => {
    setBreadcrumb(['ユーザー管理']);
  }, [setBreadcrumb]);

  // ページ選択
  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">ユーザー管理</Typography>
        {
          (() => {
            const currentUserCount = data?.results.filter((u) => u.deleted_at === null).length ?? -1;
            const userLimit = data?.user_limit ?? 0;
            const isFull = currentUserCount >= userLimit;

            return (
              <>
                <Button variant="minimum" className="titlebar-button" onClick={goCreate} disabled={isFull}>新規</Button>
                <div style={{ marginLeft: '10px', fontSize: '20px', color: isFull ? 'red' : 'black' }}>{data?.results.filter((u) => u.deleted_at === null).length} / {data?.user_limit}人</div>
                <div style={{ display: isFull ? 'block' : 'none', marginLeft: '10px', fontSize: '20px', color: 'red' }}><b>登録ユーザーの上限に達しています</b></div>
              </>
            );
          })()
        }
      </div>
      <Frame>
        <table className="u-table-row">
          <thead>
            <tr>
              <Typography variant="left" element="th">氏名</Typography>
              <Typography variant="left" element="th">フリガナ</Typography>
              <Typography variant="left" element="th" style={{ width: '240px' }}>所持資格</Typography>
              <Typography variant="left" element="th" style={{ width: '240px' }}>状態</Typography>
            </tr>
          </thead>
          <tbody>
            {data?.results.map(user => {
              let license = licenses.filter(license => user[license.key]);
              let licenseTitle = 'なし';
              if (licenses.some(license => !!user[license.key])) {
                licenseTitle = license.length > 1 ? `${license[0].title} 他${license.length - 1}つ` : license[0].title;
              }
              return (
                <tr key={user.id} onClick={() => goDetail(user)}>
                  <td>{user.name}</td>
                  <td>{user.rubi}</td>
                  <td>{licenseTitle}</td>
                  <td>
                    {
                      // user.deleted_at ? `退社 (${moment(user.deleted_at).format('YYYY年MM月DD日')})` : `在籍`
                      user.deleted_at ? <span style={{ color: 'darkred' }}>停止中</span> : <span style={{ color: 'green' }}>利用中</span>
                    }
                    <Button variant="icon" className="table-button">
                      <IconAngleRight />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Frame>
      <Pagination
        page={page}
        limit={20}
        total={data?.count}
        onSelect={(page) => setPage(page)}
        />
    </section>
  );
}

export default Users;
