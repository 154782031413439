import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useSignIn } from '~/hooks';
import {
  IconLoader,
  IconInfo,
} from '~/icons';
import {
  Typography,
  Button,
  Input,
  Field,
  Form,
} from '~/components';

function Signin({ history }) {
  const [invalid, setInvalid] = useState(false);
  const [signinMutate, { isLoading }] = useSignIn();
  const { handleSubmit, register, formState, errors } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const { isDirty } = formState;

  /**
   * ログインボタンクリックのイベントハンドラ
   */
  function handleSignin({ email, password }) {
    // リクエスト中は抜ける
    if (isLoading) return;
    // ログインAPIリクエスト
    signinMutate({
      url: '/manage/admin/login',
      params: {
        email: email.trim(),
        password: password.trim(),
      },
    }, {
      onSuccess: response => {
        if (response.status) {
          localStorage.setItem(process.env.REACT_APP_STORAGEKEY_ADMIN, response.token.original.access_token);
          localStorage.setItem(process.env.REACT_APP_ACCOUNT_DATA_ADMIN, JSON.stringify(response.result));
          history.push('/admin/study');
        } else {
          setInvalid(true);
        }
      },
      onError: error => {
        console.log(error);
      },
    });
  }

  return (
    <div className="l-minimum">
      <div className="u-holder">
        <Typography element="h1" variant="holder" className="holder-title">警備マニア管理システム</Typography>
        {invalid && (
          <div className="holder-alert">
            <Typography variant="error"><IconInfo /><span>入力されたメールアドレスやパスワードが正しくありません。確認してからやりなおしてください。</span></Typography>
          </div>
        )}
        <Form noValidate onSubmit={handleSubmit(handleSignin)} className="holder-body">
          <Field label="メールアドレス" variant="column">
            <Input
              type="email"
              name="email"
              ref={register({
                required: 'メールアドレスは必須項目です。',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'メールアドレス形式に誤りがあります。正しい内容を入力してください。',
                },
              })}
              className={errors.email && 'is-error'}
              placeholder="入力してください"
            />
            <ErrorMessage
              name="email"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
          </Field>
          <Field label="パスワード" variant="column">
            <Input
              type="password"
              name="password"
              ref={register({
                required: 'パスワードは必須項目です。',
              })}
              className={errors.password && 'is-error'}
              placeholder="入力してください"
            />
            <ErrorMessage
              name="password"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
          </Field>
          <Button
            type="submit"
            variant="formal"
            className="holder-button"
            disabled={!isDirty}
          >
            {isLoading ? <IconLoader className="a-spinner" /> : 'ログイン'}
          </Button>
        </Form>
        <aside className="u-band">
          <Typography element="h2" variant="decoration" className="band-title">お問い合わせ</Typography>
          <dl>
            <dt className="band-label">ミライズ株式会社</dt>
            <dd className="band-contents">
              <span>電話 : 024-563-5804</span>
              <span>E-mail : <Typography element="a" variant="underline" href="mailto:form-i&#64;merises.co.jp" target="_blank" rel="noopener noreferrer">form-i@merises.co.jp</Typography></span>
            </dd>
          </dl>
        </aside>
      </div>
    </div>
  );
}

export default Signin;
