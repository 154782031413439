import { useMutation } from 'react-query';
import { request, converter } from '~/utils';

function useMutant() {
  return useMutation(async props => {
    const token = localStorage.getItem(converter(process.env.REACT_APP_STORAGEKEY_ADMIN, process.env.REACT_APP_STORAGEKEY_CUSTOMER));
    let headers = { Authorization: `Bearer ${token}` };
    if (props.headers) headers = Object.assign(headers, props.headers);
    const { data } = await request.post(`${props.url}`, props.params, { headers });
    return data;
  });
}

export default useMutant;