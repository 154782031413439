import React, { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import useFormPersist from 'react-hook-form-persist';
import clsx from 'clsx';
import {
  Typography,
  Button,
  Form,
  Input,
  Field,
} from '~/components';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { IconInfo } from '~/icons';

function Create({ history }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { handleSubmit, register, errors, setValue, watch } = useForm({
    defaultValues: {
      f_name: '',
      l_name: '',
      email: '',
      password: '',
      phone: '',
    },
  });

  /**
   * フォームデータの永続化
   */
  const { clear } = useFormPersist('merisessqts_admin_accountFormData', { watch, setValue }, {
    include: [
      'f_name',
      'l_name',
      'email',
      'phone',
    ],
  });

  /**
   * 確認画面に遷移
   */
  function goConfirm(params) {
    history.push({
      pathname: `/admin/user/confirm`,
      state: params,
    });
  }

  /**
   * キャンセルボタンをクリック時のイベントハンドラ
   */
  const handleReset = useCallback(e => {
    e.preventDefault();
    clear();
    history.push('/admin/user');
  }, []);

  useEffect(() => {
    setBreadcrumb(['マスター管理', '新規登録']);
  }, []);

  return (
    <>
      <section className="l-section">
        <div className="u-titlebar">
          <Typography variant="page">新規登録</Typography>
        </div>
        <Form noValidate onSubmit={handleSubmit(goConfirm)} onReset={handleReset}>
          <Field label="名前" required={true}>
            <div className="field-row">
              <Input
                name="l_name"
                ref={register({
                  required: '名前(姓)は必須項目です。',
                })}
                className={clsx('short', errors.l_name && 'is-error')}
              />
              <Input
                name="f_name"
                ref={register({
                  required: '名前(名)は必須項目です。',
                })}
                className={clsx('short', errors.f_name && 'is-error')}
              />
              <ErrorMessage
                name="l_name"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
              <ErrorMessage
                name="f_name"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <Field label="メールアドレス" required={true}>
            <div className="field-col">
              <Input
                type="email"
                name="email"
                ref={register({
                  required: 'メールアドレスは必須項目です。',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'メールアドレス形式に誤りがあります。正しい内容を入力してください。',
                  },
                })}
                className={clsx('bob', errors.email && 'is-error')}
              />
              <ErrorMessage
                name="email"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <Field label="パスワード" required={true}>
            <div className="field-col">
              <Input
                type="password"
                name="password"
                ref={register({
                  required: 'パスワードは必須項目です。',
                  minLength: {
                    value: 8,
                    message: 'パスワードは8文字以上である必要があります。',
                  },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                    message: 'パスワードは英大文字・英小文字・数字それぞれを最低1文字ずつ含む必要があります。',
                  },
                })}
                className={clsx('short', errors.password && 'is-error')}
              />
              <ErrorMessage
                name="password"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <Field label="電話番号">
            <div className="field-col">
              <Input
                type="tel"
                name="phone"
                ref={register({
                  pattern: {
                    value: /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
                    message: '電話番号形式に誤りがあります。正しい内容を入力してください。',
                  },
                })}
                className={clsx('short', errors.phone && 'is-error')}
              />
              <ErrorMessage
                name="phone"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <div className="u-buttons">
            <Button type="reset" variant="secondary" className="buttons-item">キャンセル</Button>
            <Button type="submit" variant="primary" className="buttons-item">確認画面へ</Button>
          </div>
        </Form>
      </section>
    </>
  );
}

export default Create;
