import React, { useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import AppUserListPanel from './AppUserListPanel';
import CompanyUserListPanel from './CompanyUserListPanel';

function Information({ location, ...props }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const tabIndex = sessionStorage.getItem('tabIndex');

  /**
   * タブUI切り替えイベントハンドラ
   */
  function handleTabSelect(index) {
    sessionStorage.setItem('tabIndex', index);
  }

  useEffect(() => {
    setBreadcrumb(['お知らせ']);
  }, [setBreadcrumb]);

  return (
    <Tabs defaultIndex={tabIndex !== null ? Number(tabIndex) : 0} onSelect={handleTabSelect}>
      <TabList>
        <Tab>アプリユーザー</Tab>
        <Tab>企業ユーザー</Tab>
      </TabList>
      <TabPanel>
        <AppUserListPanel {...props} />
      </TabPanel>
      <TabPanel>
        <CompanyUserListPanel {...props} />
      </TabPanel>
    </Tabs>
  );
}

export default Information;
