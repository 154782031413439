import React, { useEffect, useState } from 'react';
import {
  IconAngleRight,
} from '~/icons';
import {
  Typography,
  Frame,
  Button,
  Pagination,
} from '~/components';
import { useRead } from '~/hooks';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';

function Study({ history, match }) {
  const [page, setPage] = useState(1);
  const { setBreadcrumb } = useBreadcrumbsContext();

  /**
   * 学習状況一覧を取得
   */
  const { data, refetch } = useRead(`study_list`, `/manage/study/index`, {
    order: 1,
    limit: 20,
    offset: 20 * (page - 1),
  }, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  /**
   * 詳細ページに遷移
   */
  function goDetail(item) {
    history.push({
      pathname: `${match.url}/${item.id}`,
      state: item,
    });
  }

  useEffect(() => {
    setBreadcrumb(['学習状況']);
  }, [setBreadcrumb]);

  // ページ選択
  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">学習状況</Typography>
      </div>
      <Frame>
        <table className="u-table-row">
          <thead>
            <tr>
              <Typography variant="left" element="th" style={{ width: `${(180 / 916) * 100}%` }}>会社名</Typography>
              <Typography variant="left" element="th" style={{ width: `${(180 / 916) * 100}%` }}>アプリ登録者数</Typography>
              <Typography variant="left" element="th">交通誘導警備業務2級 保持者</Typography>
              <Typography variant="left" element="th">交通誘導警備業務1級 保持者</Typography>
            </tr>
          </thead>
          <tbody>
            {data?.results.map(item => (
              <tr key={item.id} onClick={() => goDetail(item)}>
                <td>{item.name}</td>
                <td>{item.installed}</td>
                <td>{item.license3_2_2}</td>
                <td>{item.license3_2_1}
                  <Button variant="icon" className="table-button">
                    <IconAngleRight />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Frame>
      <Pagination
        page={page}
        limit={20}
        total={data?.count}
        onSelect={(page) => setPage(page)}
        />
    </section>
  );
}

export default Study;
