/**
 * @name timeSplit
 * @description 時刻フォーマットをsplitして配列返す
 * @param {string} time - 時刻 '20:00'
 */
function timeSplit(time) {
  let _time = time.split(':');
  return _time;
}

export default timeSplit;