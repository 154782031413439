import React from 'react';
import clsx from 'clsx';

function Typography({
  className,
  element = 'p',
  variant,
  ...other
}) {
  const Component = element;

  return (
    <Component className={clsx(`typo-${variant ? variant : element}`, className)} {...other} />
  );
}

export default React.memo(Typography, (prevProps, nextProps) => {
  if (prevProps.children === nextProps.children) {
    return true;
  }
  return false;
});