import React from 'react';
import clsx from 'clsx';

const Button = React.forwardRef(function Button(props, ref) {
  const {
    type = 'button',
    className,
    variant,
    disabled = false,
    ...other
  } = props;

  return (
    <button
      ref={ref}
      type={type}
      className={clsx(`btn-${variant}`, className)}
      disabled={disabled}
      {...other}
    />
  );
});

export default Button;
