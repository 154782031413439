import React from 'react';
import clsx from 'clsx';

const Form = React.forwardRef(function Form(props, ref) {
  const {
    className,
    onSubmit,
    ...other
  } = props;

  return (
    <form
      ref={ref}
      className={clsx('u-form', className)}
      onSubmit={onSubmit}
      {...other}
    />
  );
});

export default Form;
