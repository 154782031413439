import React, { useState, useEffect } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import usePortal from 'react-cool-portal';
import { useForm } from 'react-hook-form';
import ContentLoader from 'react-content-loader';
import { useRead, useDelete, useUpdate } from '~/hooks';
import {
  Typography,
  Frame,
  Button,
  Field,
  Textarea,
  Form,
  Input,
  Dialog,
} from '~/components';
import {
  IconCheck,
  IconLoader,
} from '~/icons';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';

function Detail({ history, match }) {
  const [modalType, setModalType] = useState('');
  const { setBreadcrumb } = useBreadcrumbsContext();
  const gender = ['未指定', '男性', '女性', 'その他'];
  const prefectures = ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false,
  });
  const { handleSubmit: handleNormalUserDelete, register: leaveRegister } = useForm();
  const { handleSubmit: handleAttentUserDelete, register: limpRegister } = useForm();
  const [userDelete, { isLoading }] = useDelete();
  const [userDeleteCancel, { isLoading: isCanceling }] = useUpdate();
  // ダイアログ用
  const [title, setTitle] = useState('');
  const [msg, setMessage] = useState('');

  /**
   * 企業ユーザー詳細を取得
   */
  const { status, data, refetch } = useRead(`user_detail_${match.params.id}`, `/manage/users/app/show`, {
    id: match.params.id,
  }, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: response => {
      setBreadcrumb(['ユーザー管理', response.result.name]);
    },
    onError: error => {
      setTitle('確認');
      // 不正なリクエスト
      if (error.response.status === 400) {
        setMessage('不正なリクエストです。');
      }
      // 権限がない
      else if (error.response.status === 403) {
        setMessage('権限がありません。');
      }
      // 見つからない
      else if (error.response.status === 404) {
        setMessage('ユーザーが存在しません。');
      }
      // その他
      else {
        setMessage('原因不明のエラーです。');
      }
      show();
    },
  });

  /**
   * 詳細ページに遷移
   */
  function goEdit(result) {
    history.push({
      pathname: `/users/edit/${match.params.id}`,
      state: result,
    });
  }

  /**
   * モーダルクローズ
   */
  function handleModalClose() {
    hide();
  }

  /**
   * モーダルオープン
   */
  function handleModalOpen(mode) {
    setModalType(mode);
    setTitle('利用停止');
    show();
  }

  /**
   * 完了モーダルのOKボタンのクリックハンドラ
   */
  function handleDeleteDone() {
    hide();
    history.push('/users');
  }

  /**
   * 退社リクエスト
   */
  function onNormalUserDelete(params) {
    userDelete({
      url: '/manage/users/app/delete',
      params: {
        id: Number(params.id),
        // retire_date: `${params.retire_date_year}-${params.retire_date_month}-${params.retire_date_day}`,
        retire_date: '1990-01-01',
        // retire_id: Number(params.retire_id),
        retire_id: 1,
        // retire_comment: params.retire_comment ? params.retire_comment : '特になし',
        retire_comment: '特になし',
      },
    }, {
      onSuccess: () => {
        refetch();
        setModalType('done');
        setTitle('完了');
        setMessage('利用停止しました。');
      },
      onError: error => {
        setModalType("");
        setTitle('確認');
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage('不正なリクエストです。');
        }
        // 権限がない
        else if (error.response.status === 403) {
          setMessage('権限がありません。');
        }
        // 見つからない
        else if (error.response.status === 404) {
          setMessage('ユーザーが存在しないか、既に退職済みです。');
        }
        // その他
        else {
          setMessage('原因不明のエラーです。');
        }
        show();
      },
    });
  }

  /**
   * 要注意人物として退社リクエスト
   */
  function onAttentUserDelete(params) {
    userDelete({
      url: '/manage/users/app/limping/delete',
      params: {
        id: Number(params.id),
        retire_date: `${params.retire_date_year}-${params.retire_date_month}-${params.retire_date_day}`,
        retire_comment: params.retire_comment ? params.retire_comment : '特になし',
      },
    }, {
      onSuccess: () => {
        refetch();
        setModalType('done');
        setTitle('完了');
        setMessage('退社しました。');
      },
      onError: error => {
        setModalType("");
        setTitle('確認');
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage('不正なリクエストです。');
        }
        // 権限がない
        else if (error.response.status === 403) {
          setMessage('権限がありません。');
        }
        // 見つからない
        else if (error.response.status === 404) {
          setMessage('ユーザーが存在しないか、既に退職済みです。');
        }
        // その他
        else {
          setMessage('原因不明のエラーです。');
        }
        show();
      },
    });
  }

  /**
   * 退社を取り消す
   */
  function handleDeleteCancel(id) {
    userDeleteCancel({
      url: '/manage/users/app/restore',
      params: {
        id,
      },
    }, {
      onSuccess: () => {
        refetch();
        setModalType('cancel');
        setTitle('送信完了');
        setMessage('利用開始しました。');
        show();
      },
      onError: error => {
        setModalType('');
        setTitle('確認');
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage('不正なリクエストです。');
        }
        // 権限がない
        else if (error.response.status === 403) {
          setMessage('権限がありません。');
        }
        // 見つからない
        else if (error.response.status === 404) {
          setMessage('ユーザーが存在しません。');
        }
        // コンフリクト
        else if (error.response.status === 409) {
          setMessage('対象のメールアドレスでユーザーが既に登録されています。');
        }
        // 上限ユーザ数
        else if (error.response.status === 413) {
          setMessage('登録ユーザーの上限に達しているため、利用を開始することができません。');
        }
        // その他
        else {
          setMessage('原因不明のエラーです。');
        }
        show();
      },
    });
  }

  /**
   * 任意の年が閏年であるかをチェックする
   */
  const isLeapYear = year => (year % 4 === 0) && ((year % 100 !== 0) || (year % 400 === 0));

  /**
   * 任意の年の2月の日数を数える
   */
  const countDatesOfFeb = year => isLeapYear(year) ? 29 : 28;

  /**
   * セレクトボックスの中にオプションを生成する
   */
  const createOption = (id, startNum, endNum, current) => {
    const selectDom = document.getElementById(id);
    let optionDom = '';
    let _option;
    for (let i = startNum; i <= endNum; i++) {
      if (i === current) {
        _option = '<option value="' + i + '" selected>' + i + '</option>';
      } else {
        _option = '<option value="' + i + '">' + i + '</option>';
      }
      optionDom += _option;
    }
    selectDom.insertAdjacentHTML('beforeend', optionDom);
  };

  useEffect(() => {
    sessionStorage.removeItem('merisessqts_customer_usersFormData_edit');
  }, []);

  useEffect(() => {
    if (modalType === 'normal') {
      // const retireDateYearEl = document.getElementById('retire_date_year');
      // const retireDateMonthEl = document.getElementById('retire_date_month');
      // const retireDateDayEl = document.getElementById('retire_date_day');
      // // 日付データ
      // const today = new Date();
      // const thisYear = today.getFullYear();
      // const thisMonth = today.getMonth() + 1;
      // const thisDate = today.getDate();
      // let datesOfYear = [31, countDatesOfFeb(thisYear), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      // // 月セレクト要素のonChange
      // retireDateMonthEl.addEventListener('change', e => {
      //   retireDateDayEl.innerHTML = '';
      //   const selectedMonth = e.target.value;
      //   createOption('retire_date_day', 1, datesOfYear[selectedMonth - 1], 1);
      // });
      // // 年セレクト要素のonChange
      // retireDateYearEl.addEventListener('change', e => {
      //   retireDateMonthEl.innerHTML = '';
      //   retireDateDayEl.innerHTML = '';
      //   const updatedYear = e.target.value;
      //   datesOfYear = [31, countDatesOfFeb(updatedYear), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      //   createOption('retire_date_month', 1, 12, 1);
      //   createOption('retire_date_day', 1, datesOfYear[0], 1);
      // });
      // // 初期セットアップ
      // createOption('retire_date_year', 1900, thisYear, thisYear);
      // createOption('retire_date_month', 1, 12, thisMonth);
      // createOption('retire_date_day', 1, datesOfYear[thisMonth - 1], thisDate);
    }
    if (modalType === 'limping') {
      const limpingRetireDateYearEl = document.getElementById('retire_date_year_limping');
      const limpingRetireDateMonthEl = document.getElementById('retire_date_month_limping');
      const limpingRetireDateDayEl = document.getElementById('retire_date_day_limping');
      // 日付データ
      const today = new Date();
      const thisYear = today.getFullYear();
      const thisMonth = today.getMonth() + 1;
      const thisDate = today.getDate();
      let datesOfYear = [31, countDatesOfFeb(thisYear), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      // 月セレクト要素のonChange
      limpingRetireDateMonthEl.addEventListener('change', e => {
        limpingRetireDateDayEl.innerHTML = '';
        const selectedMonth = e.target.value;
        createOption('retire_date_day_limping', 1, datesOfYear[selectedMonth - 1], 1);
      });
      // 年セレクト要素のonChange
      limpingRetireDateYearEl.addEventListener('change', e => {
        limpingRetireDateMonthEl.innerHTML = '';
        limpingRetireDateDayEl.innerHTML = '';
        const updatedYear = e.target.value;
        datesOfYear = [31, countDatesOfFeb(updatedYear), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        createOption('retire_date_month_limping', 1, 12, 1);
        createOption('retire_date_day_limping', 1, datesOfYear[0], 1);
      });
      // 初期セットアップ
      createOption('retire_date_year_limping', 1900, thisYear, thisYear);
      createOption('retire_date_month_limping', 1, 12, thisMonth);
      createOption('retire_date_day_limping', 1, datesOfYear[thisMonth - 1], thisDate);
    }
  });

  /**
   * 退社用。
   */
  const normalModal = () => {
    return (
      <Form noValidate onSubmit={handleNormalUserDelete(onNormalUserDelete)}>
        <div className="field-row" style={{ textAlign: 'center' }}>利用停止しますよろしいですか？</div>
      {/*
        <Field label="退職日" className="u-field-modal" element="div">
          <div className="field-row">
            <select name="retire_date_year" id="retire_date_year" className="select-primary mini" ref={leaveRegister} />
            <div className="field-text">年</div>
            <select name="retire_date_month" id="retire_date_month" className="select-primary mini" ref={leaveRegister} />
            <div className="field-text">月</div>
            <select name="retire_date_day" id="retire_date_day" className="select-primary mini" ref={leaveRegister} />
            <div className="field-text">日</div>
          </div>
        </Field>
        <Field label="退社理由" className="u-field-modal" element="div">
          <div className="field-col">
            <select name="retire_id" className="select-primary short" ref={leaveRegister}>
              <option value="1">自己都合</option>
            </select>
          </div>
        </Field>
        <Field label="フリーコメント" className="u-field-modal">
          <div className="field-col">
            <Textarea
              placeholder="勤務態度など評価を記載"
              rows="3"
              name="retire_comment"
              ref={leaveRegister}
            />
            <span className="field-meta">※個人情報を含むコメントは入力しないでください。</span>
          </div>
        </Field>
      */}
        <Input
          type="hidden"
          name="id"
          value={match.params.id}
          ref={leaveRegister}
        />
        <div className="modal-btnbar">
          <Button type="reset" variant="primary" className="buttons-item" onClick={hide}>キャンセル</Button>
          <Button type="submit" variant="secondary" className="buttons-item">
            {isLoading ? <IconLoader className="a-spinner" /> : 'OK'}
          </Button>
        </div>
      </Form>
    );
  };

  /**
   * 要注意人物に登録する用。
   */
  const limpingModal = () => {
    return (
      <Form noValidate onSubmit={handleAttentUserDelete(onAttentUserDelete)}>
        <Field label="退職日" className="u-field-modal" element="div">
          <div className="field-row">
            <select name="retire_date_year" id="retire_date_year_limping" className="select-primary mini" ref={limpRegister} />
            <div className="field-text">年</div>
            <select name="retire_date_month" id="retire_date_month_limping" className="select-primary mini" ref={limpRegister} />
            <div className="field-text">月</div>
            <select name="retire_date_day" id="retire_date_day_limping" className="select-primary mini" ref={limpRegister} />
            <div className="field-text">日</div>
          </div>
        </Field>
        <Field label="フリーコメント" className="u-field-modal">
          <div className="field-col">
            <Textarea
              placeholder="勤務態度など評価を記載"
              rows="3"
              name="retire_comment"
              ref={limpRegister}
            />
            <span className="field-meta">※個人情報を含むコメントは入力しないでください。</span>
          </div>
        </Field>
        <Input
          type="hidden"
          name="id"
          value={match.params.id}
          ref={limpRegister}
        />
        <div className="modal-btnbar">
          <Button type="reset" variant="primary" className="buttons-item" onClick={hide}>キャンセル</Button>
          <Button type="submit" variant="secondary" className="buttons-item">
            {isLoading ? <IconLoader className="a-spinner" /> : 'OK'}
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <>
      <section className="l-section">
        <div className="u-titlebar-between">
          <div className="titlebar-left">
            {status === 'success' ? (
              <Typography variant="page">{data?.result.name}</Typography>
            ) : (
                <ContentLoader
                  speed={2}
                  width={120}
                  height={38}
                  viewBox="0 0 120 38"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="120" height="38" />
                </ContentLoader>
              )}
            {status === 'success' && !data?.result.deleted_at && (
              <Button variant="minimum" className="titlebar-button" onClick={() => goEdit(data?.result)}>編集</Button>
            )}
          </div>
          <div className="titlebar-right">
            {status === 'success' && (
              <>
                {data?.result.deleted_at ? (
                  <Button variant="warning" className="titlebar-button" onClick={() => handleDeleteCancel(data?.result.id)}>
                    {isCanceling ? <IconLoader className="a-spinner" /> : '利用する'}
                  </Button>
                ) : (
                    <>
                      {/*<Button variant="warning" className="titlebar-button" onClick={() => handleModalOpen('limping')}>要注意人物に登録して退社</Button>*/}
                      <Button variant="warning" className="titlebar-button" onClick={() => handleModalOpen('normal')}>利用停止</Button>
                    </>
                  )}
              </>
            )}
          </div>
        </div>
        <Frame>
          <table className="u-table-column">
            <tbody>
              <tr>
                <th style={{ width: '180px' }}>名前</th>
                <td>{data?.result.name}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>フリガナ</th>
                <td>{data?.result.rubi}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>メールアドレス</th>
                <td>{data?.result.email}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>生年月日</th>
                <td>
                  {
                    status === 'success' && data?.result.birth_date && moment(data?.result.birth_date).format('YYYY年MM月DD日')
                  }
                </td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>性別</th>
                <td>{gender[data?.result.sex_id]}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>電話番号</th>
                <td>{data?.result.phone}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>郵便番号</th>
                <td>{data?.result.postal_code}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>住所</th>
                <td>{!!data?.result.master_area_prefecture_id && prefectures[data?.result.master_area_prefecture_id - 1]} {data?.result.address}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>入社日</th>
                <td>{status === 'success' && moment(data?.result.hire_date).format('YYYY年MM月DD日')}</td>
              </tr>
              <tr>
                <th style={{ verticalAlign: 'top', width: '180px' }}>所持資格</th>
                <td>
                  {!!data?.result.license1_1 && <>指導教育責任者１号警備<br /></>}
                  {!!data?.result.license1_2 && <>指導教育責任者２号警備<br /></>}
                  {!!data?.result.license1_3 && <>指導教育責任者３号警備<br /></>}
                  {!!data?.result.license1_4 && <>指導教育責任者４号警備<br /></>}
                  {!!data?.result.license2_1 && <>機械警備業務管理者<br /></>}
                  {!!data?.result.license3_1_1 && <>１号警備 施設警備業務検定１級<br /></>}
                  {!!data?.result.license3_1_2 && <>１号警備 施設警備業務検定２級<br /></>}
                  {!!data?.result.license3_1_3 && <>１号警備 空港保安警備業務検定１級<br /></>}
                  {!!data?.result.license3_1_4 && <>１号警備 空港保安警備業務検定２級<br /></>}
                  {!!data?.result.license3_2_1 && <>２号警備 交通誘導警備業務検定１級<br /></>}
                  {!!data?.result.license3_2_2 && <>２号警備 交通誘導警備業務検定２級<br /></>}
                  {!!data?.result.license3_2_3 && <>２号警備 雑踏警備業務検定１級<br /></>}
                  {!!data?.result.license3_2_4 && <>２号警備 雑踏警備業務検定２級<br /></>}
                  {!!data?.result.license3_3_1 && <>３号警備 貴重品運搬警備業務検定１級<br /></>}
                  {!!data?.result.license3_3_2 && <>３号警備 貴重品運搬警備業務検定２級<br /></>}
                  {!!data?.result.license3_3_3 && <>３号警備 核燃料輸送警備業務検定１級<br /></>}
                  {!!data?.result.license3_3_4 && <>３号警備 核燃料輸送警備業務検定２級<br /></>}
                </td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>状態</th>
                <td>{status === 'success' && (
                  <>
                    {
                      //data?.result.deleted_at ? `退社 (${moment(data?.result.deleted_at).format('YYYY年MM月DD日')})` : `在籍`
                      data?.result.deleted_at ? '停止中' : '利用中'
                    }
                  </>
                )}</td>
              </tr>
            </tbody>
          </table>
        </Frame>
      </section>
      <Portal>
        <Dialog
          title={title}
          message={msg}
          handleModalClose={hide}
          isShow={isShow}
          modalType={modalType}
          content={
            modalType === 'normal' ? normalModal() : modalType === 'limping' ? limpingModal() : null
          }
        />
      </Portal>
    </>
  );
}

export default Detail;
