import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import usePortal from 'react-cool-portal';
import { useMutant } from '~/hooks';
import {
  Typography,
  Field,
  Input,
  Button,
  Form,
} from '~/components';
import {
  IconInfo,
  IconLoader,
  IconCheck,
  IconWarning,
} from '~/icons';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';

function Check() {
  const [marked, setMarked] = useState(false);
  const [limpingCheck, { isLoading }] = useMutant();
  const { handleSubmit, register, errors, reset, clearErrors } = useForm();
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false,
  });

  /**
   * 任意の年が閏年であるかをチェックする
   */
  const isLeapYear = year => (year % 4 === 0) && ((year % 100 !== 0) || (year % 400 === 0));

  /**
   * 任意の年の2月の日数を数える
   */
  const countDatesOfFeb = year => isLeapYear(year) ? 29 : 28;

  /**
   * セレクトボックスの中にオプションを生成する
   */
  const createOption = (id, startNum, endNum, current) => {
    const selectDom = document.getElementById(id);
    let optionDom = '';
    let _option;
    for (let i = startNum; i <= endNum; i++) {
      if (i === current) {
        _option = '<option value="' + i + '" selected>' + i + '</option>';
      } else {
        _option = '<option value="' + i + '">' + i + '</option>';
      }
      optionDom += _option;
    }
    selectDom.insertAdjacentHTML('beforeend', optionDom);
  };

  /**
   * 送信ボタンクリック時のsubmitイベントハンドラ
   */
  function handleUserCheck(params) {
    // リクエスト中は抜ける
    if (isLoading) return;
    // 要注意人物チェックリクエスト
    limpingCheck({
      url: '/manage/users/app/limping/check',
      params: {
        name: `${params.l_name} ${params.f_name}`,
        rubi: `${params.l_kana} ${params.f_kana}`,
        birth_date: `${params.year}-${params.month}-${params.day}`,
      },
    }, {
      onSuccess: response => {
        setMarked(response.limping);
        show();
      },
      onError: error => {
        console.log(error);
      },
    });
  }

  /**
   * クリアボタンクリック時のresetイベントハンドラ
   */
  function handleUserCheckReset(e) {
    e.preventDefault();
    reset({
      l_name: '',
      f_name: '',
      l_kana: '',
      f_kana: '',
    });
    clearErrors();
  }

  /**
   * モーダルクローズ
   */
  function handleModalClose() {
    reset({
      l_name: '',
      f_name: '',
      l_kana: '',
      f_kana: '',
    });
    clearErrors();
    hide();
  }

  useEffect(() => {
    setBreadcrumb(['ユーザーチェック']);

    // DOM
    const yearBox = document.getElementById('year');
    const monthBox = document.getElementById('month');
    const dateBox = document.getElementById('day');

    // 日付データ
    const today = new Date();
    const thisYear = today.getFullYear();
    const thisMonth = today.getMonth() + 1;
    const thisDate = today.getDate();

    let datesOfYear = [31, countDatesOfFeb(thisYear), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // 月セレクト要素のonChange
    monthBox.addEventListener('change', (e) => {
      dateBox.innerHTML = '';
      const selectedMonth = e.target.value;
      createOption('day', 1, datesOfYear[selectedMonth - 1], 1);
    });

    // 年セレクト要素のonChange
    yearBox.addEventListener('change', e => {
      monthBox.innerHTML = '';
      dateBox.innerHTML = '';
      const updatedYear = e.target.value;
      datesOfYear = [31, countDatesOfFeb(updatedYear), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      createOption('month', 1, 12, 1);
      createOption('day', 1, datesOfYear[0], 1);
    });

    // 初期セットアップ
    createOption('year', 1900, thisYear, thisYear);
    createOption('month', 1, 12, thisMonth);
    createOption('day', 1, datesOfYear[thisMonth - 1], thisDate);
  }, [setBreadcrumb]);

  return (
    <>
      <section className="l-section">
        <div className="u-titlebar">
          <Typography variant="page">ユーザーチェック</Typography>
        </div>
        <Typography variant="lead">氏名と生年月日を入力すると、システム内でユーザーチェックを行えます。<br />
        同姓同名、同誕生日の別人の可能性もあります。情報の取り扱いには十分ご注意下さい。</Typography>
        <Form noValidate onSubmit={handleSubmit(handleUserCheck)} onReset={handleUserCheckReset}>
          <Field label="名前">
            <div className="field-col">
              <Input
                name="l_name"
                ref={register({
                  required: '名前(姓)は必須項目です。',
                })}
                className={clsx('short', errors.l_name && 'is-error')}
              />
              <Input
                name="f_name"
                ref={register({
                  required: '名前(名)は必須項目です。',
                })}
                className={clsx('short', errors.f_name && 'is-error')}
              />
              <ErrorMessage
                name="l_name"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
              <ErrorMessage
                name="f_name"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <Field label="フリガナ">
            <div className="field-col">
              <Input
                name="l_kana"
                ref={register({
                  required: 'フリガナ(姓)は必須項目です。',
                  pattern: {
                    value: /^[ァ-ンヴー]*$/,
                    message: 'フリガナ(姓)をカタカナで入力してください。',
                  },
                })}
                className={clsx('short', errors.l_kana && 'is-error')}
              />
              <Input
                name="f_kana"
                ref={register({
                  required: 'フリガナ(名)は必須項目です。',
                  pattern: {
                    value: /^[ァ-ンヴー]*$/,
                    message: 'フリガナ(名)をカタカナで入力してください。',
                  },
                })}
                className={clsx('short', errors.f_kana && 'is-error')}
              />
              <ErrorMessage
                name="l_kana"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
              <ErrorMessage
                name="f_kana"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <Field label="生年月日" element="div">
            <select name="year" id="year" className="select-primary field-col mini" ref={register} />
            <div className="field-text">年</div>
            <select name="month" id="month" className="select-primary field-col mini" ref={register} />
            <div className="field-text">月</div>
            <select name="day" id="day" className="select-primary field-col mini" ref={register} />
            <div className="field-text">日</div>
          </Field>
          <div className="u-buttons">
            <Button type="reset" variant="secondary" className="buttons-item">クリア</Button>
            <Button type="submit" variant="primary" className="buttons-item">
              {isLoading ? <IconLoader className="a-spinner" /> : '確認'}
            </Button>
          </div>
        </Form>
      </section>
      <Portal>
        <div className={clsx('u-modal', isShow && 'is-show')} tabIndex={-1}>
          <div className="modal-overlay" onClick={handleModalClose} />
          <div className="modal-body">
            <div className="modal-icon">{marked ? <IconWarning /> : <IconCheck />}</div>
            <Typography className="modal-title">{marked ? '確認' : '問題ありません'}</Typography>
            <Typography className="modal-text">{marked ? 'コメントが登録されています。' : 'このユーザーには問題はありません。'}</Typography>
            <Button className="modal-button" variant="formal" onClick={handleModalClose}>OK</Button>
          </div>
        </div>
      </Portal>
    </>
  );
}

export default Check;
