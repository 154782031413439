import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import {
  Typography,
  Frame,
} from '~/components';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';

function Detail({ location }) {
  const { setBreadcrumb } = useBreadcrumbsContext();

  useEffect(() => {
    setBreadcrumb(['お知らせ', '詳細']);
  }, [setBreadcrumb]);

  if (!location.state) return <Redirect to="/admin/information" />;

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">{location.state.title}</Typography>
      </div>
      <Frame>
        <table className="u-table-column">
          <tbody>
            <tr>
              <th style={{ width: '180px' }}>{location.state.to === 1 ? '差出先' : '投稿先'}</th>
              <td>{
                location.state.to === 0 ? 'アプリユーザー' : location.state.to === 1 ? (location.state.account_company_id === 0 ? '全ての企業' : location.state.account_company.name) : '全ての企業'
              }</td>
            </tr>
            <tr>
              <th style={{ width: '180px' }}>{location.state.to === 1 ? '受信日時' : '投稿日時'}</th>
              <td>{moment(location.state.created_at).format('YYYY/MM/DD HH:mm')}</td>
            </tr>
            <tr>
              <th style={{ width: '180px' }}>タイトル</th>
              <td>{location.state.title}</td>
            </tr>
            <tr>
              <th style={{ verticalAlign: 'top', width: '180px' }}>内容</th>
              <td>
                <pre>{location.state.comment}</pre>
              </td>
            </tr>
          </tbody>
        </table>
      </Frame>
    </section>
  );
}

export default Detail;
