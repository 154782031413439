import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Typography,
  Frame,
  Button,
  Pagination,
} from '~/components';
import { IconAngleRight } from '~/icons';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { useRead } from '~/hooks';
import { converter } from '~/utils';

function User({ history, match }) {
  const [page, setPage] = useState(1);
  const { setBreadcrumb } = useBreadcrumbsContext();
  const accountKey = converter(process.env.REACT_APP_ACCOUNT_DATA_ADMIN, process.env.REACT_APP_ACCOUNT_DATA_CUSTOMER);
  const account = JSON.parse(localStorage.getItem(accountKey));

  /**
   * アドミンアカウント一覧取得
   */
  const { data, refetch } = useRead(`admin_account_list`, `/manage/users/admin/index`, {
    order: 1,
    limit: 20,
    offset: 20 * (page - 1),
  }, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  /**
   * 詳細ページに遷移
   */
  function goDetail(result) {
    history.push({
      pathname: `${match.url}/detail/${result.id}`,
      state: result,
    });
  }

  /**
   * 新規作成ページに遷移
   */
  function goCreate() {
    history.push(`${match.url}/create`);
  }

  useEffect(() => {
    setBreadcrumb(['マスター管理']);
  }, [setBreadcrumb]);

  // ページ選択
  useEffect(() => {
    refetch();
  }, [page]);

  if (!account?.super) return <Redirect to={`/admin/user/detail/${account.id}`} />;

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">マスター管理</Typography>
        <Button variant="minimum" className="titlebar-button" onClick={goCreate}>新規</Button>
      </div>
      <Frame>
        <table className="u-table-row">
          <thead>
            <tr>
              <Typography variant="left" element="th" style={{ width: `${(180 / 916) * 100}%` }}>名前</Typography>
              <Typography variant="left" element="th">メールアドレス</Typography>
            </tr>
          </thead>
          <tbody>
            {data?.results.map(result => (
              <tr key={`account_list_${result.id}`} onClick={() => goDetail(result)}>
                <td>{result.name}</td>
                <td>{result.email}
                  <Button variant="icon" className="table-button">
                    <IconAngleRight />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Frame>
      <Pagination
        page={page}
        limit={20}
        total={data?.count}
        onSelect={(page) => setPage(page)}
        />
    </section>
  );
}

export default User;
