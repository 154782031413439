import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import usePortal from 'react-cool-portal';
import {
  Typography,
  Button,
  Frame,
  Form,
  Input,
  Dialog,
} from '~/components';
import { IconLoader } from '~/icons';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { useMutant, useUpdate } from '~/hooks';

function Confirm({ history, location }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { handleSubmit, register } = useForm();
  const [accountCreate, { isLoading: isCreating }] = useMutant();
  const [accountUpdate, { isLoading: isUploading }] = useUpdate();
  const isLoading = !!location.state.id ? isUploading : isCreating;
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false,
  });
  // ダイアログ用
  const [msg, setMessage] = useState('');

  /**
   * 新規作成Submitイベント
   */
  function onCreate(values) {
    // リクエスト中は抜ける
    if (isLoading) return;
    // アカウント作成リクエスト
    accountCreate({
      url: '/manage/users/admin/new',
      params: {
        name: values.name,
        email: values.email,
        password: values.password,
        phone: values.phone,
      },
    }, {
      onSuccess: () => {
        sessionStorage.removeItem('merisessqts_admin_accountFormData');
        history.push('/admin/user');
      },
      onError: error => {
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage('不正なリクエストです。');
        }
        // 権限なし
        else if (error.response.status === 403) {
          setMessage('権限がありません。');
        }
        // コンフリクト
        else if (error.response.status === 409) {
          setMessage('ユーザーが既に存在します。');
        }
        // その他
        else {
          setMessage('原因不明のエラーが発生しました。');
        }
        show();
      },
    });
  }

  /**
   * 更新Submitイベント
   */
  function onUpdate(values) {
    // リクエスト中は抜ける
    if (isLoading) return;
    // アカウント更新リクエスト
    accountUpdate({
      url: '/manage/users/admin/update',
      params: {
        id: Number(values.id),
        name: values.name,
        email: values.email,
        password: values.password,
        phone: values.phone,
      },
    }, {
      onSuccess: () => {
        sessionStorage.removeItem('merisessqts_admin_accountFormData_edit');
        history.push('/admin/user');
      },
      onError: error => {
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage('不正なリクエストです。');
        }
        // 権限なし
        else if (error.response.status === 403) {
          setMessage('権限がありません。');
        }
        // 見つからない
        else if (error.response.status === 404) {
          setMessage('ユーザーが存在しません。');
        }
        // コンフリクト
        else if (error.response.status === 409) {
          setMessage('ユーザーが既に存在します。');
        }
        // その他
        else {
          setMessage('原因不明のエラーが発生しました。');
        }
        show();
      },
    });
  }

  /**
   * 入力画面に戻る
   */
  function goBack() {
    history.goBack();
  }

  useEffect(() => {
    setBreadcrumb(['マスター管理', '確認']);
  }, [setBreadcrumb]);

  if (!location.state) return <Redirect to="/users" />;

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">確認</Typography>
      </div>
      <Form noValidate onSubmit={handleSubmit(!!location.state.id ? onUpdate : onCreate)}>
        <Frame>
          <table className="u-table-column">
            <tbody>
              <tr>
                <th style={{ width: '180px' }}>名前</th>
                <td>{`${location.state.l_name} ${location.state.f_name}`}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>メールアドレス</th>
                <td>{location.state.email}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>電話番号</th>
                <td>{location.state.phone}</td>
              </tr>
            </tbody>
          </table>
        </Frame>
        {!!location.state.id && (
          <Input
            type="hidden"
            name="id"
            value={location.state.id}
            ref={register}
          />
        )}
        <Input
          type="hidden"
          name="name"
          value={`${location.state.l_name} ${location.state.f_name}`}
          ref={register}
        />
        <Input
          type="hidden"
          name="email"
          value={location.state.email}
          ref={register}
        />
        <Input
          type="hidden"
          name="password"
          value={location.state.password}
          ref={register}
        />
        <Input
          type="hidden"
          name="phone"
          value={location.state.phone}
          ref={register}
        />
        <div className="u-buttons">
          <Button variant="secondary" className="buttons-item" onClick={goBack}>戻る</Button>
          <Button type="submit" variant="primary" className="buttons-item">
            {isLoading ? <IconLoader className="a-spinner" /> : '登録'}
          </Button>
        </div>
      </Form>
      <Portal>
        <Dialog
          title="確認"
          message={msg}
          handleModalClose={hide}
          isShow={isShow}
        />
      </Portal>
    </section>
  );
}

export default Confirm;
