/**
 * @name nameSplit
 * @description ユーザーネームをsplitして配列返す
 * @param {string} name
 */
function nameSplit(name) {
  let _name = name.split(' ');
  return _name;
}

export default nameSplit;