import React, { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import useFormPersist from 'react-hook-form-persist';
import clsx from 'clsx';
import {
  Typography,
  Button,
  Form,
  Input,
  Field,
} from '~/components';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { IconInfo } from '~/icons';

function Create({ history }) {
  const formData = JSON.parse(sessionStorage.getItem('merisessqts_customer_usersFormData_create'));
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { handleSubmit, register, errors, setValue, watch } = useForm({
    defaultValues: {
      email: '',
      password: '',
      f_name: '',
      l_name: '',
      f_kana: '',
      l_kana: '',
      gender: 0,
      phone: '',
      postal_code: '',
      master_area_prefecture_id: 13,
      address: '',
      license1_1: 0,
      license1_2: 0,
      license1_3: 0,
      license1_4: 0,
      license2_1: 0,
      license3_1_1: 0,
      license3_1_2: 0,
      license3_1_3: 0,
      license3_1_4: 0,
      license3_2_1: 0,
      license3_2_2: 0,
      license3_2_3: 0,
      license3_2_4: 0,
      license3_3_1: 0,
      license3_3_2: 0,
      license3_3_3: 0,
      license3_3_4: 0,
    },
  });

  /**
   * フォームデータの永続化
   */
  const { clear } = useFormPersist('merisessqts_customer_usersFormData_create', { watch, setValue }, {
    include: [
      'birth_date_year',
      'birth_date_month',
      'birth_date_day',
      'hire_date_year',
      'hire_date_month',
      'hire_date_day',
      'email',
      'f_name',
      'l_name',
      'f_kana',
      'l_kana',
      'gender',
      'phone',
      'postal_code',
      'master_area_prefecture_id',
      'address',
      'license1_1',
      'license1_2',
      'license1_3',
      'license1_4',
      'license2_1',
      'license3_1_1',
      'license3_1_2',
      'license3_1_3',
      'license3_1_4',
      'license3_2_1',
      'license3_2_2',
      'license3_2_3',
      'license3_2_4',
      'license3_3_1',
      'license3_3_2',
      'license3_3_3',
      'license3_3_4',
    ],
  });

  /**
   * 確認画面に遷移
   */
  function goConfirm(params) {
    history.push({
      pathname: `/users/confirm`,
      state: params,
    });
  }

  /**
   * 任意の年が閏年であるかをチェックする
   */
  const isLeapYear = year => (year % 4 === 0) && ((year % 100 !== 0) || (year % 400 === 0));

  /**
   * 任意の年の2月の日数を数える
   */
  const countDatesOfFeb = year => isLeapYear(year) ? 29 : 28;

  /**
   * セレクトボックスの中にオプションを生成する
   */
  const createOption = (id, startNum, endNum, current) => {
    const selectDom = document.getElementById(id);
    let optionDom = '';
    let _option;
    if (id === 'birth_date_year') {
      optionDom += '<option value="" hidden>yyyy</option>';
    }
    else if (id === 'birth_date_month') {
      optionDom += '<option value="" hidden>MM</option>'
    }
    else if (id === 'birth_date_day') {
      optionDom += '<option value="" hidden>dd</option>'
    }
    for (let i = startNum; i <= endNum; i++) {
      if (i === current) {
        _option = '<option value="' + i + '" selected>' + i + '</option>';
      } else {
        _option = '<option value="' + i + '">' + i + '</option>';
      }
      optionDom += _option;
    }
    selectDom.insertAdjacentHTML('beforeend', optionDom);
  };

  /**
   * キャンセルボタンをクリック時のイベントハンドラ
   */
  const handleReset = useCallback(e => {
    e.preventDefault();
    clear();
    history.push('/users');
  }, []);

  useEffect(() => {
    setBreadcrumb(['ユーザー管理', '新規作成']);

    // DOM
    const birthDateYearEl = document.getElementById('birth_date_year');
    const birthDateMonthEl = document.getElementById('birth_date_month');
    const birthDateDayEl = document.getElementById('birth_date_day');
    const hireDateYearEl = document.getElementById('hire_date_year');
    const hireDateMonthEl = document.getElementById('hire_date_month');
    const hireDateDayEl = document.getElementById('hire_date_day');

    // 日付データ
    const today = new Date();
    const thisYear = today.getFullYear();
    const thisMonth = today.getMonth() + 1;
    const thisDate = today.getDate();

    // うるう年対応の月別末日の配列
    let datesOfYear = [31, countDatesOfFeb(thisYear), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // 月セレクト要素のonChange
    birthDateMonthEl.addEventListener('change', e => {
      birthDateDayEl.innerHTML = '';
      const selectedMonth = e.target.value;
      createOption('birth_date_day', 1, datesOfYear[selectedMonth - 1], 1);
    });

    // 年セレクト要素のonChange
    birthDateYearEl.addEventListener('change', e => {
      birthDateMonthEl.innerHTML = '';
      birthDateDayEl.innerHTML = '';
      const updatedYear = e.target.value;
      datesOfYear = [31, countDatesOfFeb(updatedYear), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      createOption('birth_date_month', 1, 12, 1);
      createOption('birth_date_day', 1, datesOfYear[0], 1);
    });

    // 月セレクト要素のonChange
    hireDateMonthEl.addEventListener('change', e => {
      hireDateDayEl.innerHTML = '';
      const selectedMonth = e.target.value;
      createOption('hire_date_day', 1, datesOfYear[selectedMonth - 1], 1);
    });

    // 年セレクト要素のonChange
    hireDateYearEl.addEventListener('change', e => {
      hireDateMonthEl.innerHTML = '';
      hireDateDayEl.innerHTML = '';
      const updatedYear = e.target.value;
      datesOfYear = [31, countDatesOfFeb(updatedYear), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      createOption('hire_date_month', 1, 12, 1);
      createOption('hire_date_day', 1, datesOfYear[0], 1);
    });

    // 初期セットアップ
    createOption('birth_date_year', 1900, thisYear, formData ? Number(formData.birth_date_year) : -1);
    createOption('birth_date_month', 1, 12, formData ? Number(formData.birth_date_month) : -1);
    createOption('birth_date_day', 1, datesOfYear[thisMonth - 1], formData ? Number(formData.birth_date_day) : -1);
    createOption('hire_date_year', 1900, thisYear, formData ? Number(formData.hire_date_year) : thisYear);
    createOption('hire_date_month', 1, 12, formData ? Number(formData.hire_date_month) : thisMonth);
    createOption('hire_date_day', 1, datesOfYear[thisMonth - 1], formData ? Number(formData.hire_date_day) : thisDate);
    return () => {
      if (history.location.pathname !== '/users/confirm') {
        clear();
      }
    };
  }, []);

  return (
    <>
      <section className="l-section">
        <div className="u-titlebar">
          <Typography variant="page">新規作成</Typography>
        </div>
        <Form noValidate onSubmit={handleSubmit(goConfirm)} onReset={handleReset}>
          <Field label="名前" required={true}>
            <div className="field-row">
              <Input
                name="l_name"
                ref={register({
                  required: '名前(姓)は必須項目です。',
                })}
                className={clsx('short', errors.l_name && 'is-error')}
              />
              <Input
                name="f_name"
                ref={register({
                  required: '名前(名)は必須項目です。',
                })}
                className={clsx('short', errors.f_name && 'is-error')}
              />
              <ErrorMessage
                name="l_name"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
              <ErrorMessage
                name="f_name"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <Field label="フリガナ" required={true}>
            <div className="field-row">
              <Input
                name="l_kana"
                ref={register({
                  required: 'フリガナ(姓)は必須項目です。',
                  pattern: {
                    value: /^[ァ-ンヴー]*$/,
                    message: 'フリガナ(姓)をカタカナで入力してください。',
                  },
                })}
                className={clsx('short', errors.l_kana && 'is-error')}
              />
              <Input
                name="f_kana"
                ref={register({
                  required: 'フリガナ(名)は必須項目です。',
                  pattern: {
                    value: /^[ァ-ンヴー]*$/,
                    message: 'フリガナ(名)をカタカナで入力してください。',
                  },
                })}
                className={clsx('short', errors.f_kana && 'is-error')}
              />
              <ErrorMessage
                name="l_kana"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
              <ErrorMessage
                name="f_kana"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <Field label="メールアドレス" required={true}>
            <div className="field-col">
              <Input
                type="email"
                name="email"
                ref={register({
                  required: 'メールアドレスは必須項目です。',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'メールアドレス形式に誤りがあります。正しい内容を入力してください。',
                  },
                })}
                className={clsx('bob', errors.email && 'is-error')}
              />
              <ErrorMessage
                name="email"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <Field label="パスワード">
            <div className="field-col">
              <Input
                type="password"
                name="password"
                ref={register({
                  minLength: {
                    value: 8,
                    message: 'パスワードは8文字以上である必要があります。',
                  },
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                    message: 'パスワードは英大文字・英小文字・数字それぞれを最低1文字ずつ含む必要があります。',
                  },
                })}
                className={clsx('short', errors.password && 'is-error')}
              />
              <ErrorMessage
                name="password"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <Field label="生年月日">
            <div className="field-row">
              <select name="birth_date_year" id="birth_date_year" className="select-primary mini" ref={register} />
              <div className="field-text">年</div>
              <select name="birth_date_month" id="birth_date_month" className="select-primary mini" ref={register} />
              <div className="field-text">月</div>
              <select name="birth_date_day" id="birth_date_day" className="select-primary mini" ref={register} />
              <div className="field-text">日</div>
            </div>
          </Field>
          <Field label="性別">
            <div className="field-col">
              <select name="gender" className="select-primary short" ref={register}>
                <option value="0">未指定</option>
                <option value="1">男性</option>
                <option value="2">女性</option>
                <option value="3">その他</option>
              </select>
            </div>
          </Field>
          <Field label="電話番号">
            <div className="field-col">
              <Input
                type="tel"
                name="phone"
                ref={register({
                  pattern: {
                    value: /^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/,
                    message: '電話番号形式に誤りがあります。正しい内容を入力してください。',
                  },
                })}
                className="short"
              />
              <ErrorMessage
                name="phone"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <Field label="郵便番号">
            <div className="field-col">
              <Input
                name="postal_code"
                ref={register({
                  pattern: {
                    value: /^\d{3}[-]?\d{4}$/,
                    message: '郵便番号形式に誤りがあります。正しい内容を入力してください。',
                  },
                })}
                className="short"
              />
              <ErrorMessage
                name="postal_code"
                errors={errors}
                render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
              />
            </div>
          </Field>
          <Field label="住所">
            <div className="field-col">
              <select name="master_area_prefecture_id" className="select-primary short" ref={register}>
                <option value="1">北海道</option>
                <option value="2">青森県</option>
                <option value="3">岩手県</option>
                <option value="4">宮城県</option>
                <option value="5">秋田県</option>
                <option value="6">山形県</option>
                <option value="7">福島県</option>
                <option value="8">茨城県</option>
                <option value="9">栃木県</option>
                <option value="10">群馬県</option>
                <option value="11">埼玉県</option>
                <option value="12">千葉県</option>
                <option value="13">東京都</option>
                <option value="14">神奈川県</option>
                <option value="15">新潟県</option>
                <option value="16">富山県</option>
                <option value="17">石川県</option>
                <option value="18">福井県</option>
                <option value="19">山梨県</option>
                <option value="20">長野県</option>
                <option value="21">岐阜県</option>
                <option value="22">静岡県</option>
                <option value="23">愛知県</option>
                <option value="24">三重県</option>
                <option value="25">滋賀県</option>
                <option value="26">京都府</option>
                <option value="27">大阪府</option>
                <option value="28">兵庫県</option>
                <option value="29">奈良県</option>
                <option value="30">和歌山県</option>
                <option value="31">鳥取県</option>
                <option value="32">島根県</option>
                <option value="33">岡山県</option>
                <option value="34">広島県</option>
                <option value="35">山口県</option>
                <option value="36">徳島県</option>
                <option value="37">香川県</option>
                <option value="38">愛媛県</option>
                <option value="39">高知県</option>
                <option value="40">福岡県</option>
                <option value="41">佐賀県</option>
                <option value="42">長崎県</option>
                <option value="43">熊本県</option>
                <option value="44">大分県</option>
                <option value="45">宮崎県</option>
                <option value="46">鹿児島県</option>
                <option value="47">沖縄県</option>
              </select>
              <Input
                name="address"
                ref={register}
              />
            </div>
          </Field>
          <Field label="入社日" element="div">
            <div className="field-row">
              <select name="hire_date_year" id="hire_date_year" className="select-primary mini" ref={register} />
              <div className="field-text">年</div>
              <select name="hire_date_month" id="hire_date_month" className="select-primary mini" ref={register} />
              <div className="field-text">月</div>
              <select name="hire_date_day" id="hire_date_day" className="select-primary mini" ref={register} />
              <div className="field-text">日</div>
            </div>
          </Field>
          <Field label="所持資格" element="div">
            <div className="field-col">
              <div className="u-stairs">
                <div className="stairs-label">指導教育責任者</div>
                <div className="stairs-body u-checkboxbar">
                  <div className="checkboxbar-row">
                    <label className="checkbox-primary">
                      <input type="checkbox" name="license1_1" className="checkbox-origin" ref={register} />
                      <span className="checkbox-label">１号警備</span>
                    </label>
                    <label className="checkbox-primary">
                      <input type="checkbox" name="license1_2" className="checkbox-origin" ref={register} />
                      <span className="checkbox-label">２号警備</span>
                    </label>
                    <label className="checkbox-primary">
                      <input type="checkbox" name="license1_3" className="checkbox-origin" ref={register} />
                      <span className="checkbox-label">３号警備</span>
                    </label>
                    <label className="checkbox-primary">
                      <input type="checkbox" name="license1_4" className="checkbox-origin" ref={register} />
                      <span className="checkbox-label">４号警備</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="u-stairs">
                <div className="stairs-label">機械警備業務管理者</div>
                <div className="stairs-body u-checkboxbar">
                  <div className="checkboxbar-row">
                    <label className="checkbox-primary">
                      <input type="checkbox" name="license2_1" className="checkbox-origin" ref={register} />
                      <span className="checkbox-label">機械警備業務管理者</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="u-stairs">
                <div className="stairs-label">警備員業務検定</div>
                <div className="stairs-body">
                  <div className="u-checkboxbar">
                    <div className="checkboxbar-tag">１号警備</div>
                    <div className="checkboxbar-body">
                      <div className="checkboxbar-row">
                        <div className="checkboxbar-label">施設警備業務検定</div>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_1_1" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">１級</span>
                        </label>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_1_2" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">２級</span>
                        </label>
                      </div>
                      <div className="checkboxbar-row">
                        <div className="checkboxbar-label">空港保安警備業務検定</div>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_1_3" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">１級</span>
                        </label>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_1_4" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">２級</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="u-checkboxbar">
                    <div className="checkboxbar-tag">２号警備</div>
                    <div className="checkboxbar-body">
                      <div className="checkboxbar-row">
                        <div className="checkboxbar-label">交通誘導警備業務検定</div>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_2_1" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">１級</span>
                        </label>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_2_2" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">２級</span>
                        </label>
                      </div>
                      <div className="checkboxbar-row">
                        <div className="checkboxbar-label">雑踏警備業務検定</div>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_2_3" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">１級</span>
                        </label>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_2_4" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">２級</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="u-checkboxbar">
                    <div className="checkboxbar-tag">３号警備</div>
                    <div className="checkboxbar-body">
                      <div className="checkboxbar-row">
                        <div className="checkboxbar-label">貴重品運搬警備業務</div>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_3_1" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">１級</span>
                        </label>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_3_2" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">２級</span>
                        </label>
                      </div>
                      <div className="checkboxbar-row">
                        <div className="checkboxbar-label">核燃料輸送警備業務検定</div>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_3_3" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">１級</span>
                        </label>
                        <label className="checkbox-primary">
                          <input type="checkbox" name="license3_3_4" className="checkbox-origin" ref={register} />
                          <span className="checkbox-label">２級</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Field>
          <div className="u-buttons">
            <Button type="reset" variant="secondary" className="buttons-item">キャンセル</Button>
            <Button type="submit" variant="primary" className="buttons-item">確認画面へ</Button>
          </div>
        </Form>
      </section>
    </>
  );
}

export default Create;
