import React, { useEffect } from 'react';
import clsx from 'clsx';
import Chart from 'chart.js';
import moment from 'moment';

const AdminBarChart = React.forwardRef(function BarChart(props, ref) {
  const { data, className, ...other } = props;

  useEffect(() => {
    var ctx = document.getElementById('bar-2').getContext('2d');
    var chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        datasets: [{
          label: '筆記試験学習時間(警備マニア)',
          backgroundColor: '#F0B21E',
          borderColor: '#F0B21E',
          data: [
            moment.duration(data?.month.study_time[0].total1, 'seconds').minutes(),
            moment.duration(data?.month.study_time[0].total2, 'seconds').minutes(),
            moment.duration(data?.month.study_time[0].total3, 'seconds').minutes(),
            moment.duration(data?.month.study_time[0].total4, 'seconds').minutes(),
            moment.duration(data?.month.study_time[0].total5, 'seconds').minutes(),
            moment.duration(data?.month.study_time[0].total6, 'seconds').minutes(),
            moment.duration(data?.month.study_time[0].total7, 'seconds').minutes(),
            moment.duration(data?.month.study_time[0].total8, 'seconds').minutes(),
            moment.duration(data?.month.study_time[0].total9, 'seconds').minutes(),
            moment.duration(data?.month.study_time[0].total10, 'seconds').minutes(),
            moment.duration(data?.month.study_time[0].total11, 'seconds').minutes(),
            moment.duration(data?.month.study_time[0].total12, 'seconds').minutes(),
          ],
        }],
      },
      options: {
        animation: false,
        legend: {
          display: false,
        },
        responsive: true,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: '#333',
              fontSize: 12,
            },
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: '#E3E3E3',
              drawTicks: false,
              drawBorder: false,
            },
            ticks: {
              autoSkip: true,
              fontColor: '#333',
              fontSize: 12,
            },
          }],
        },
      },
    });
  }, [data]);

  return (
    <div ref={ref} className={clsx('u-bar', className)} {...other}>
      <canvas id="bar-2"></canvas>
      <p className="bar-label">分</p>
    </div>
  );
});

export default AdminBarChart;
