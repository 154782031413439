import React from 'react';
import clsx from 'clsx';

const Textarea = React.forwardRef(function Textarea(props, ref) {
  const {
    type = 'text',
    name,
    value,
    className,
    onChange,
    variant = 'primary',
    ...other
  } = props;

  return (
    <textarea
      name={name}
      className={clsx(`textarea-${variant}`, className)}
      onChange={onChange}
      ref={ref}
      {...other}
    />
  );
});

export default Textarea;
