import React, { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import useFormPersist from 'react-hook-form-persist';
import {
  Typography,
  Form,
  Field,
  Input,
  Button,
  Textarea,
} from '~/components';
import { IconInfo } from '~/icons';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { useRead } from '~/hooks';

function Create({ history }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { handleSubmit, register, errors, setValue, watch } = useForm({
    defaultValues: {
      to: '0',
      account_company_id: '0',
      title: '',
      comment: '',
    },
  });
  const watchTo = watch('to');

  /**
   * フォームデータの永続化
   */
  const { clear } = useFormPersist('merisessqts_admin_infoFormData', { watch, setValue }, {
    include: [
      'to',
      'account_company_id',
      'title',
      'comment',
    ],
  });

  /**
   * 学習状況一覧を取得
   */
  const { status, data } = useRead(`study_list`, `/manage/study/index`, {
    order: 1,
  }, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  /**
   * 入力確認画面に遷移する
   */
  function goConfirm(params) {
    history.push({
      pathname: `/admin/information/confirm`,
      state: {
        users: data?.results,
        params,
      },
    });
  }

  /**
   * キャンセルボタンをクリック時のイベントハンドラ
   */
  const handleReset = useCallback(e => {
    e.preventDefault();
    clear();
    history.push('/admin/information');
  }, []);

  useEffect(() => {
    setBreadcrumb(['お知らせ', '新規作成']);
  }, []);

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">新規作成</Typography>
      </div>
      <Form noValidate onSubmit={handleSubmit(goConfirm)} onReset={handleReset}>
        <Field label="投稿先">
          <div className="field-col middle">
            <select name="to" className="select-primary field-col" ref={register}>
              {status !== 'success'
                ? (
                  <option disabled>Loading...</option>
                )
                : (
                  <>
                    <option disabled>選択してください</option>
                    <option value="0">アプリユーザー</option>
                    <option value="1">企業</option>
                  </>
                )
              }
            </select>
          </div>
        </Field>
        {watchTo === '1' && (
          <Field label="投稿企業" id="account_company_id">
            <div className="field-col middle">
              <select name="account_company_id" className="select-primary field-col" ref={register}>
                {status !== 'success'
                  ? (
                    <option disabled>Loading...</option>
                  )
                  : (
                    <>
                      <option disabled>選択してください</option>
                      <option value="0">すべての企業</option>
                      {data?.results.map(result => (
                        <option key={`post_options_${result.id}`} value={result.id}>{result.name}</option>
                      ))}
                    </>
                  )
                }
              </select>
            </div>
          </Field>
        )}
        <Field label="タイトル">
          <div className="field-col">
            <Input
              name="title"
              ref={register({
                required: 'タイトルは必須項目です。',
              })}
              className={errors.title && 'is-error'}
            />
            <ErrorMessage
              name="title"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
          </div>
        </Field>
        <Field label="内容">
          <div className="field-col">
            <Textarea
              rows="8"
              name="comment"
              ref={register({
                required: '内容は必須項目です。',
              })}
              className={errors.title && 'is-error'}
            />
            <ErrorMessage
              name="comment"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
          </div>
        </Field>
        <div className="u-buttons">
          <Button type="reset" variant="secondary" className="buttons-item">キャンセル</Button>
          <Button type="submit" variant="primary" className="buttons-item">確認画面へ</Button>
        </div>
      </Form>
    </section>
  );
}

export default Create;
