import React, { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import useFormPersist from 'react-hook-form-persist';
import TextareaAutosize from 'react-textarea-autosize';
import {
  Typography,
  Button,
  Form,
  Input,
  Field,
} from '~/components';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import { IconInfo } from '~/icons';

function Create({ history }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const { handleSubmit, register, errors, setValue, watch } = useForm({
    defaultValues: {
      question_category_id: '1',
      question_html: '',
      question_sound: '',
      answer_1_html: '',
      answer_1_sound: '',
      answer_2_html: '',
      answer_2_sound: '',
      answer_3_html: '',
      answer_3_sound: '',
      answer_4_html: '',
      answer_4_sound: '',
      answer_5_html: '',
      answer_5_sound: '',
      answer_correct_number: '',
      answer_explanation_html: '',
      answer_explanation_sound: '',
    },
  });
  const watchAllFields = watch();

  /**
   * フォームデータの永続化
   */
  const { clear } = useFormPersist('merisessqts_admin_questionFormData', { watch, setValue }, {
    include: [
      'question_category_id',
      'question_html',
      'answer_1_html',
      'answer_2_html',
      'answer_3_html',
      'answer_4_html',
      'answer_5_html',
      'answer_correct_number',
      'answer_explanation_html',
    ],
  });

  /**
   * 確認画面に遷移
   */
  function goConfirm(params) {
    history.push({
      pathname: `/admin/question/confirm`,
      state: params,
    });
  }

  /**
   * キャンセルボタンをクリック時のイベントハンドラ
   */
  const handleReset = useCallback(e => {
    e.preventDefault();
    clear();
    history.push('/admin/question');
  }, []);

  useEffect(() => {
    setBreadcrumb(['設問管理', '新規登録']);
  }, []);

  return (
    <section className="l-section">
      <div className="u-titlebar">
        <Typography variant="page">新規登録</Typography>
      </div>
      <Form noValidate onSubmit={handleSubmit(goConfirm)} onReset={handleReset}>
        <Typography variant="caption">カテゴリー</Typography>
        <Field label="カテゴリー">
          <div className="field-row">
            <select name="question_category_id" className="select-primary bob" ref={register}>
              <option value="1">基本的事項</option>
              <option value="2">関係法令</option>
              <option value="3">車両等誘導</option>
              <option value="4">応急処置</option>
              <option value="5">その他</option>
            </select>
          </div>
        </Field>
        <hr className="u-separate-offset" noshade="true" />
        <Typography variant="caption">問題</Typography>
        <Field label="問題" element="div" style={{ marginTop: '24px' }}>
          <div className="field-col">
            <TextareaAutosize
              name="question_html"
              className="textarea-primary field-item"
              ref={register({
                required: '選択肢文は必須項目です。',
              })}
            />
            <ErrorMessage
              name="question_html"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
            <span className="field-meta">{`例) <span title="どうこうほうだいじゅうにじょう" url="https://...">道交法第12条</span>`}</span>
            <label className="file-primary field-item">
              <div className="file-label">{!!watchAllFields.question_sound.length && watchAllFields.question_sound[0].name}</div>
              <span className="file-button">音声ファイルを選択</span>
              <input
                type="file"
                name="question_sound"
                className="file-origin"
                accept="audio/*"
                ref={register({
                  validate: value => {
                    const regexp = /^audio\//;
                    if (!value.length) return true;
                    return regexp.test(value[0]?.type) || 'ファイル形式に誤りがあります。音声ファイルを用意してください。';
                  },
                })}
              />
            </label>
            <ErrorMessage
              name="question_sound"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
          </div>
        </Field>
        <hr className="u-separate" noshade="true" />
        {[...Array(5)].map((_, i) => {
          const index = i + 1;
          return (
            <Field label={`選択肢${index}`} element="div" key={`answers_field_${index}`} style={{ marginTop: '24px' }}>
              <div className="field-col">
                <label className="radio-primary">
                  <input
                    type="radio"
                    name="answer_correct_number"
                    value={index}
                    className="radio-origin"
                    ref={register({
                      required: '正解選択肢は必須項目です。選択肢1〜5のいずれかを選んでください。',
                    })}
                  />
                  <span className="radio-label">正解</span>
                </label>
                <ErrorMessage
                  name="answer_correct_number"
                  errors={errors}
                  render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
                />
                <TextareaAutosize
                  name={`answer_${index}_html`}
                  className="textarea-primary field-item"
                  ref={register({
                    required: '選択肢文は必須項目です。',
                  })}
                />
                <ErrorMessage
                  name={`answer_${index}_html`}
                  errors={errors}
                  render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
                />
                <label className="file-primary field-item">
                  <div className="file-label">{!!watchAllFields[`answer_${index}_sound`].length && watchAllFields[`answer_${index}_sound`][0].name}</div>
                  <span className="file-button">音声ファイルを選択</span>
                  <input
                    type="file"
                    name={`answer_${index}_sound`}
                    className="file-origin"
                    accept="audio/*"
                    ref={register({
                      validate: value => {
                        const regexp = /^audio\//;
                        if (!value.length) return true;
                        return regexp.test(value[0]?.type) || 'ファイル形式に誤りがあります。音声ファイルを用意してください。';
                      },
                    })}
                  />
                </label>
                <ErrorMessage
                  name={`answer_${index}_sound`}
                  errors={errors}
                  render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
                />
              </div>
            </Field>
          );
        })}
        <hr className="u-separate-offset" noshade="true" />
        <hr className="u-separate-offset" noshade="true" />
        <Typography variant="caption">解説</Typography>
        <Field label="解説" element="div" style={{ marginTop: '24px' }}>
          <div className="field-col">
            <TextareaAutosize
              name="answer_explanation_html"
              className="textarea-primary field-item"
              ref={register({
                required: '選択肢文は必須項目です。',
              })}
            />
            <ErrorMessage
              name="answer_explanation_html"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
            <label className="file-primary field-item">
              <div className="file-label">{!!watchAllFields['answer_explanation_sound']?.length && watchAllFields['answer_explanation_sound'][0].name}</div>
              <span className="file-button">音声ファイルを選択</span>
              <input
                type="file"
                name="answer_explanation_sound"
                className="file-origin"
                accept="audio/*"
                ref={register({
                  validate: value => {
                    const regexp = /^audio\//;
                    if (!value.length) return true;
                    return regexp.test(value[0]?.type) || 'ファイル形式に誤りがあります。音声ファイルを用意してください。';
                  },
                })}
              />
            </label>
            <ErrorMessage
              name="answer_explanation_sound"
              errors={errors}
              render={({ message }) => <p className="field-error"><IconInfo /><span>{message}</span></p>}
            />
          </div>
        </Field>
        <div className="u-buttons">
          <Button type="reset" variant="secondary" className="buttons-item">キャンセル</Button>
          <Button type="submit" variant="primary" className="buttons-item">確認画面へ</Button>
        </div>
      </Form>
    </section>
  );
}

export default Create;
