import { useMutation } from 'react-query';
import { request, converter } from '~/utils';

function useDelete() {
  return useMutation(async props => {
    const token = localStorage.getItem(converter(process.env.REACT_APP_STORAGEKEY_ADMIN, process.env.REACT_APP_STORAGEKEY_CUSTOMER));
    const headers = { Authorization: `Bearer ${token}` };
    const { data } = await request.delete(props.url, {
      headers,
      params: props.params,
    });
    return data;
  });
}

export default useDelete;