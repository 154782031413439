import {
  AdminStudyIndex,
  AdminStudyDetail,
  AdminInfoIndex,
  AdminInfoDetail,
  AdminInfoCreate,
  AdminInfoConfirm,
  AdminQuestion,
  AdminQuestionDetail,
  AdminQuestionCreate,
  AdminQuestionEdit,
  AdminQuestionConfirm,
  AdminCompany,
  AdminCompanyDetail,
  AdminCompanyEdit,
  AdminCompanyConfirm,
  AdminCompanyCreate,
  AdminUser,
  AdminUserDetail,
  AdminUserCreate,
  AdminUserEdit,
  AdminUserConfirm,
  AdminLimping,
  AdminLimpingDetail,
  CustomerStudyIndex,
  CustomerStudyDetail,
  CustomerInfoIndex,
  CustomerInfoDetail,
  CustomerInfoCreate,
  CustomerInfoConfirm,
  CustomerUsersIndex,
  CustomerUsersDetail,
  CustomerUsersEdit,
  CustomerUsersCreate,
  CustomerUsersConfirm,
  CustomerCheck,
} from '~/pages';
import {
  IconUser,
  IconBuilding,
  IconTweet,
  IconBookOpen,
  IconBook,
  IconClipboard,
} from '~/icons';

const routes_admin = [
  {
    title: '学習状況',
    path: '/admin/study',
    component: AdminStudyIndex,
    icon: IconBookOpen,
    routes: [{
      title: '学習状況-詳細',
      path: '/:id',
      component: AdminStudyDetail,
    }],
  },
  {
    title: 'お知らせ',
    path: '/admin/information',
    component: AdminInfoIndex,
    icon: IconTweet,
    routes: [
      {
        title: 'お知らせ-詳細',
        path: '/detail',
        component: AdminInfoDetail,
      },
      {
        title: 'お知らせ-新規作成',
        path: '/create',
        component: AdminInfoCreate,
      },
      {
        title: 'お知らせ-新規作成-確認',
        path: '/confirm',
        component: AdminInfoConfirm,
      },
    ],
  },
  {
    title: '設問管理',
    path: '/admin/question',
    component: AdminQuestion,
    icon: IconBook,
    routes: [
      {
        title: '詳細',
        path: '/detail/:id',
        component: AdminQuestionDetail,
      },
      {
        title: '新規作成',
        path: '/create',
        component: AdminQuestionCreate,
      },
      {
        title: '編集',
        path: '/edit/:id',
        component: AdminQuestionEdit,
      },
      {
        title: '確認',
        path: '/confirm',
        component: AdminQuestionConfirm,
      },
    ],
  },
  {
    title: '利用企業管理',
    path: '/admin/company',
    component: AdminCompany,
    icon: IconBuilding,
    routes: [
      {
        title: '詳細',
        path: '/detail/:id',
        component: AdminCompanyDetail,
      },
      {
        title: '新規登録',
        path: '/create',
        component: AdminCompanyCreate,
      },
      {
        title: '編集',
        path: '/edit/:id',
        component: AdminCompanyEdit,
      },
      {
        title: '確認',
        path: '/confirm',
        component: AdminCompanyConfirm,
      },
    ],
  },
  {
    title: 'マスター管理',
    path: '/admin/user',
    component: AdminUser,
    icon: IconUser,
    routes: [
      {
        title: 'アカウント管理-詳細',
        path: '/detail/:id',
        component: AdminUserDetail,
      },
      {
        title: 'アカウント管理-新規登録',
        path: '/create',
        component: AdminUserCreate,
      },
      {
        title: 'アカウント管理-編集',
        path: '/edit/:id',
        component: AdminUserEdit,
      },
      {
        title: 'アカウント管理-確認',
        path: '/confirm',
        component: AdminUserConfirm,
      },
    ],
  },
  /*
  {
    title: '要注意人物一覧',
    path: '/admin/limping',
    component: AdminLimping,
    icon: IconClipboard,
    permissions: 'super',
    routes: [
      {
        title: '詳細',
        path: '/:name',
        component: AdminLimpingDetail,
      },
    ],
  },
  */
];

const routes_customer = [
  {
    title: '学習状況',
    path: '/study',
    component: CustomerStudyIndex,
    icon: IconBookOpen,
    routes: [{
      title: '学習状況-詳細',
      path: '/:id',
      component: CustomerStudyDetail,
    }],
  },
  {
    title: 'お知らせ',
    path: '/information',
    component: CustomerInfoIndex,
    icon: IconTweet,
    routes: [
      {
        title: 'お知らせ-詳細',
        path: '/detail',
        component: CustomerInfoDetail,
      },
      {
        title: 'お知らせ-新規作成',
        path: '/create',
        component: CustomerInfoCreate,
      },
      {
        title: 'お知らせ-新規作成-確認',
        path: '/confirm',
        component: CustomerInfoConfirm,
      },
    ],
  },
  {
    title: 'ユーザー管理',
    path: '/users',
    component: CustomerUsersIndex,
    icon: IconBook,
    routes: [
      {
        title: 'ユーザー管理-新規作成',
        path: '/create',
        component: CustomerUsersCreate,
      },
      {
        title: 'ユーザー管理-詳細',
        path: '/detail/:id',
        component: CustomerUsersDetail,
      },
      {
        title: 'ユーザー管理-編集',
        path: '/edit/:id',
        component: CustomerUsersEdit,
      },
      {
        title: 'ユーザー管理-確認',
        path: '/confirm',
        component: CustomerUsersConfirm,
      },
    ],
  },
  /*
  {
    title: 'ユーザーチェック',
    path: '/limping',
    component: CustomerCheck,
    icon: IconClipboard,
  },
  */
];

export {
  routes_admin,
  routes_customer,
};