import { useMutation } from 'react-query';
import { request, converter } from '~/utils';

function useUpdate() {
  return useMutation(async props => {
    const token = localStorage.getItem(converter(process.env.REACT_APP_STORAGEKEY_ADMIN, process.env.REACT_APP_STORAGEKEY_CUSTOMER));
    const headers = { Authorization: `Bearer ${token}` };
    const { data } = await request.put(`${props.url}`, props.params, { headers });
    return data;
  });
}

export default useUpdate;