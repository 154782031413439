import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import clsx from 'clsx';
import ContentLoader from 'react-content-loader';
import usePortal from 'react-cool-portal';
import {
  Typography,
  Frame,
  Button,
  Dialog,
} from '~/components';
import { useBreadcrumbsContext } from '~/contexts/breadcrumbsContext';
import {
  useRead,
  useDelete,
} from '~/hooks';
import {
  IconLoader,
  IconCheck,
  IconWarning,
} from '~/icons';

function Detail({ location, history, match }) {
  const { setBreadcrumb } = useBreadcrumbsContext();
  const [companyDelete, { isLoading: isDeleting }] = useDelete();
  const { Portal, isShow, show, hide } = usePortal({
    defaultShow: false,
    internalShowHide: false,
  });
  // ダイアログ用
  const [title, setTitle] = useState('');
  const [msg, setMessage] = useState('');
  const [isForm, setForm] = useState(false);

  /**
   * 企業ユーザー詳細を取得
   */
  const { status, data, refetch } = useRead(`company_detail_${match.params.id}`, `/manage/users/company/show`, {
    id: match.params.id,
  }, {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: response => {
      setBreadcrumb(['利用企業管理', response.result.name]);
    },
    onError: error => {
      setTitle('確認');
      setForm(false);
      // 不正なリクエスト
      if (error.response.status === 400) {
        setMessage('不正なリクエストです。');
      }
      // 権限がない
      else if (error.response.status === 403) {
        setMessage('権限がありません。');
      }
      // 見つからない
      else if (error.response.status === 404) {
        setMessage('企業アカウントが存在しません。');
      }
      // その他
      else {
        setMessage('原因不明のエラーです。');
      }
      show();
    },
  });

  /**
   * 編集ページに遷移
   */
  function goEdit(result) {
    history.push({
      pathname: `/admin/company/edit/${result.id}`,
      state: result,
    });
  }

  /**
   * モーダルクローズ
   */
  function handleModalClose() {
    hide();
  }

  /**
   * 削除確認モーダルオープン
   */
  function handleConfirmModalOpen() {
    setTitle('コンテンツの削除');
    setMessage('削除しますか？');
    setForm(true);
    show();
  }

  /**
   * アドミンアカウントを削除
   */
  function handleDeleteClick() {
    // リクエスト中は抜ける
    if (isDeleting) return;
    // 削除リクエスト
    companyDelete({
      url: '/manage/users/company/delete',
      params: {
        id: Number(data?.result.id),
      },
    }, {
      onSuccess: () => {
        history.push('/admin/company');
      },
      onError: error => {
        setTitle('確認');
        setForm(false);
        // 不正なリクエスト
        if (error.response.status === 400) {
          setMessage('不正なリクエストです。');
        }
        // 権限がない
        else if (error.response.status === 403) {
          setMessage('権限がありません。');
        }
        // 見つからない
        else if (error.response.status === 404) {
          setMessage('企業アカウントが存在しません。');
        }
        // その他
        else {
          setMessage('原因不明のエラーです。');
        }
        show();
      },
    });
  }

  useEffect(() => {
    sessionStorage.removeItem('merisessqts_admin_companyFormData_edit');
  }, []);

  return (
    <>
      <section className="l-section">
        <div className="u-titlebar-between">
          <div className="titlebar-left">
            {status === 'success' ? (
              <>
                <Typography variant="page">{data?.result.name}</Typography>
                <Button variant="minimum" className="titlebar-button" onClick={() => goEdit(data?.result)}>編集</Button>
              </>
            ) : (
                <ContentLoader
                  speed={2}
                  width={120}
                  height={38}
                  viewBox="0 0 120 38"
                  backgroundColor="#f3f3f3"
                  foregroundColor="#ecebeb"
                >
                  <rect x="0" y="0" rx="3" ry="3" width="120" height="38" />
                </ContentLoader>
              )}
          </div>
          <div className="titlebar-right">
            {status === 'success' && <Button variant="warning" className="titlebar-button" onClick={handleConfirmModalOpen}>削除</Button>}
          </div>
        </div>
        <Frame>
          <table className="u-table-column">
            <tbody>
              <tr>
                <th style={{ width: '180px' }}>会社名</th>
                <td>{data?.result.name}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>メールアドレス</th>
                <td>{data?.result.email}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>電話番号</th>
                <td>{data?.result.phone}</td>
              </tr>
              <tr>
                <th style={{ width: '180px' }}>上限ユーザ数</th>
                {
                  (() => {
                    const limitOver = (data?.result.user_limit ?? 0) <= (data?.result.active_user_count ?? -1);
                    return (
                      <td style={{ color: limitOver ? 'red' : 'darkgreen' }}>
                        {data?.result.active_user_count}/{data?.result.user_limit}人
                      </td>
                    );
                  })()
                }
              </tr>
            </tbody>
          </table>
        </Frame>
      </section>
      <Portal>
        <Dialog
          title={title}
          message={msg}
          handleModalClose={handleModalClose}
          handleExecuteClick={handleDeleteClick}
          isShow={isShow}
          form={isForm}
          isExecuting={isDeleting}
        />
      </Portal>
    </>
  );
}

export default Detail;
