import { useMutation } from 'react-query';
import { request } from '~/utils';

function useSignIn() {
  return useMutation(async props => {
    const { data } = await request.post(props.url, props.params);
    return data;
  });
}

export default useSignIn;